export const SPECIALIZED = [
    { path: 'homedevice', text: 'At-Home Laser Hair Removal' },
    { path: 'active-lifestyle', text: 'Laser Hair Removal For Active Lifestyle' },
    {
        path: 'medicallyrecommended/#amputee',
        text: 'Laser Hair Removal For Amputees',
    },
    { path: 'bodybuilding', text: 'Laser Hair Removal For Bodybuilders' },
    { path: 'areas/darkskin/', text: 'Laser Hair Removal For Brown Skin' },
    { path: 'folliculitis', text: 'Laser Hair Removal For Folliculitis' },
    {
        path: 'medicallyrecommended/#hs',
        text: 'Laser Hair Removal For Hidradenitis Suppurativa',
    },
    { path: 'pcos', text: 'Laser Hair Removal For PCOS' },
    {
        path: 'medicallyrecommended/#cysts',
        text: 'Laser Hair Removal For Pilonidal Cysts',
    },
    { path: 'trans', text: 'Laser Hair Removal For Transgender Clients' },
];

export const ABOUT_MILAN_ITEMS = [
    { path: 'blog', text: 'Blog' },
    { path: 'careers', text: 'Careers' },
    // { path: 'covid', text: 'Our COVID Precautions' },
    { path: 'military', text: 'Military Discount On Laser Hair Removal' },
    { path: 'press', text: 'Press' },
    { path: 'privacy', text: 'Privacy Policy' },
    { path: 'terms', text: 'Terms and Conditions' },
    // { path: 'cookies', text: 'Cookie Preferences' },
    // { path: 'donotsell', text: 'Do Not Sell My Personal Information' },
];
