import React from 'react';
import { Link } from 'gatsby';
import { BlogData } from '../../types/blogs/blogHero';

// Define the props type for the component
interface PopularBlogsContentProps {
    blogs: BlogData[];
}

export function PopularBlogsContent({ blogs }: PopularBlogsContentProps) {
    return (
        //  horizontal scrolling for medium screens
        <div className="w-full sm:pr-4 py-30 overflow-x-auto">
            <div className="flex flex-nowrap justify-between gap-x-6">
                {blogs.map((blog, index) => (
                    <div
                        key={index}
                        className="blog-card max-w-[320px] min-h-[388.747px] md:max-w-[416px] md:h-[505px] w-full relative min-w-[320px] xl:w-[416px]xl:h-[505px] rounded-3xl overflow-hidden"
                    >
                        {/* Blog Image */}
                        <img
                            src={blog?.image || '/placeholder.jpg'}
                            alt={blog?.imageAlt || 'Blog post image'}
                            className="blog-image w-full h-full object-cover transform scale-100 transition-transform duration-300 ease-in-out"
                        />
                        {/* Overlay with Title */}
                        <div className="bg-overlay-grey absolute inset-0 flex items-center pointer-events-none">
                            <h3 className="blog-title text-5xl font-mediumTitle leading-[48px] tracking-[-2px] m-0 px-10  lg:px-16   text-center text-white transition-opacity duration-300">
                                {blog?.headline || 'Default Blog Title'}
                            </h3>
                        </div>
                        {/* Link wraps the entire blog card, enabling full-card clickability */}
                        <Link
                            to={`/blog${blog?.pageUrl}` || '/blog'}
                            className="stretched-link"
                            aria-label={blog?.headline || 'Default Blog Title'}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}
