// BlogHeroContent.tsx
import React from 'react';
import { Typography } from '../../../Typography/Typography';

export function BlogHeroContent({
    publishDate,
    minuteRead,
    headline,
    categories,
    fullName,
    avatarImage,
    imageAltText,
    onAuthorClick,
    showCategories,
    blogUrl,
    handleCategoryButtonClick,
}: {
    publishDate: string;
    minuteRead: number;
    headline: string;
    categories: string[];
    fullName: string;
    avatarImage: string;
    imageAltText: string;
    onAuthorClick?: () => void;
    showCategories?: boolean;
    blogUrl: string;
    handleCategoryButtonClick: (category: string) => void;
}) {
    return (
        <div className="w-[336px] xl:w-[525px] shrink-0 relative z-10">
            <div className="h-[477px] bg-milan-blue rounded-[20px] p-[20px] flex flex-col justify-center space-y-5">
                <div>
                    <div className="flex items-center gap-2  mb-6">
                        <Typography variant="h4Footer" color="milan-text-white" tag="span">
                            {publishDate}
                        </Typography>
                        <Typography variant="h4Footer" color="milan-text-white" tag="span">
                            •
                        </Typography>
                        <Typography variant="h4Footer" color="milan-text-white" tag="span">
                            {minuteRead} MINUTE READ
                        </Typography>
                    </div>

                    <Typography
                        variant="h1SmallTitle"
                        color="milan-text-white"
                        tag="h1"
                        className="!pb-0 mb-6 break-words overflow-wrap break-word"
                    >
                        {headline}
                    </Typography>
                </div>

                <div className="space-y-5">
                    <div className="flex items-center gap-3">
                        <div
                            className="w-[51.544px] h-[51.544px] rounded-full overflow-hidden cursor-pointer"
                            onClick={onAuthorClick}
                            role="button"
                            aria-label={`View ${fullName}'s profile`}
                        >
                            <img
                                src={avatarImage}
                                alt={`${imageAltText || 'Author'}'s photo`}
                                className="w-full h-full object-cover"
                            />
                        </div>
                        <button
                            aria-label={`learn more about blog author ${fullName}`}
                            onClick={onAuthorClick} //this is going to be dynamic, just a placeholder for now
                            className="flex flex-col items-center outline-none border-none bg-transparent h-full"
                        >
                            <Typography
                                variant="pDefault"
                                color="milan-text-white"
                                tag="p"
                                className="m-0 underline hover:opacity-70"
                            >
                                {fullName}
                            </Typography>
                        </button>
                    </div>

                    {/* Conditional render based on showCategories prop */}
                    {showCategories ? (
                        <div className="flex flex-col md:flex-row flex-wrap justify-start gap-[20.62px] md:w-full">
                            {categories.map((category, index) => (
                                <button
                                    key={index}
                                    onClick={() => handleCategoryButtonClick(category)}
                                    className="bg-milan-secondary-blue text-milan-navy px-[19.33px] py-[6.33px] rounded-[12.89px] font-medium flex items-center justify-center hover:bg-milan-white outline-none border-none font-open-sans text-base"
                                >
                                    {category}
                                </button>
                            ))}
                        </div>
                    ) : (
                        <div className="flex flex-row justify-start flex-wrap md:w-full">
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (typeof window !== 'undefined') {
                                        window.location.href = blogUrl;
                                    }
                                }}
                                className="bg-milan-secondary-blue text-milan-navy px-[19.33px] py-[6.33px] rounded-[12.89px] font-medium flex items-center justify-center hover:bg-milan-white outline-none border-none font-open-sans"
                            >
                                <Typography variant="pDefault" color="milan-text-navy" tag="span" className="!m-0 !p-0">
                                    Read More
                                </Typography>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
