import { BuilderContextProps } from 'components/builder/types';
import { Typography } from '../../../Typography/Typography';
import React from 'react';

type AuthorHero = {
    authorName: string;
    authorJobTitle: string;
    authorIntro: string;
    authorAvatar: string;
    authorAvatarImageAlt: string;
    builderState?: BuilderContextProps;
};

type AuthorNameOnly = Pick<AuthorHero, 'authorAvatar' | 'authorAvatarImageAlt'>;

export function AuthorAvatarImage({ authorAvatar, authorAvatarImageAlt }: AuthorNameOnly) {
    return (
        <div className="">
            <img
                src={authorAvatar}
                alt={authorAvatarImageAlt}
                className="rounded-full h-[193px] w-[193px] lg:h-[387px] lg:w-[387px]"
            />
        </div>
    );
}

export function AuthorHero({
    authorAvatar,
    authorJobTitle,
    authorName,
    authorIntro,
    authorAvatarImageAlt,
    builderState,
}: AuthorHero) {
    const fullName = builderState?.state.authors?.data?.fullName || authorName;
    const avatarImage = builderState?.state.authors?.data?.image || authorAvatar;
    const jobTitle = builderState?.state.authors?.data?.jobTitle || authorJobTitle;
    const imageAltText = builderState?.state.authors?.data?.imageAltText || authorAvatarImageAlt;
    const quickSummary = builderState?.state.authors?.data?.authorIntro || authorIntro;

    return (
        <>
            <section className="sm:max-w-[768px] lg:max-w-[1200px] mx-auto max-md:hidden md:py-[50px] lg:py-[100px]">
                <div className="flex flex-col items-start sm:px-4 xl:px-0">
                    <div className="flex items-center justify-center gap-[45px]">
                        <AuthorAvatarImage authorAvatar={avatarImage} authorAvatarImageAlt={imageAltText} />
                        <div className="flex flex-col justify-center gap-[30px] p-0 m-0">
                            <Typography variant={'h1SmallTitle'} className="uppercase m-0" tag={'h2'}>
                                Meet The Author
                            </Typography>
                            <div className="flex flex-col justify-center gap-[10px]">
                                <Typography
                                    variant={'pSubheadLg'}
                                    color={'milan-text-primary'}
                                    tag={'p'}
                                    className="m-0"
                                >
                                    {fullName}
                                </Typography>
                                <Typography variant={'h4Footer'} tag={'p'} color={'milan-text-tur'} className="m-0">
                                    {jobTitle}
                                </Typography>
                            </div>
                            {/* optional only display if quick summary is present */}
                            {quickSummary && (
                                <Typography variant={'pDefault'} tag={'p'} color="milan-text-navy" className="m-0">
                                    {quickSummary}
                                </Typography>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            {/* Mobile View */}
            <section className="max-w-screen-md mx-auto md:hidden min-h-[488px] relative">
                <div className="flex flex-col items-center relative">
                    <div className="meet-author-container bg-milan-blue w-screen flex justify-center items-start pt-[50px] min-h-[200px] relative">
                        <Typography variant={'h1SmallTitle'} color={'text-white'} className="uppercase m-0" tag={'h2'}>
                            Meet The Author
                        </Typography>
                    </div>

                    <div className="flex flex-col justify-center items-center w-full px-4 pt-28 pb-[50px]">
                        <div className="flex flex-col items-center">
                            {/* Avatar Image */}
                            <div className="absolute top-[102px]">
                                <AuthorAvatarImage authorAvatar={avatarImage} authorAvatarImageAlt={imageAltText} />
                            </div>
                            <Typography
                                variant={'pSubheadLg'}
                                color={'milan-text-primary'}
                                tag={'p'}
                                className="m-0 pb-3"
                            >
                                {fullName}
                            </Typography>
                            <Typography variant={'h4Footer'} tag={'p'} color={'milan-text-tur'} className="m-0 pb-7">
                                {jobTitle}
                            </Typography>
                        </div>
                        {quickSummary && (
                            <Typography variant={'pDefault'} tag={'p'} color="milan-text-navy" className="m-0">
                                {quickSummary}
                            </Typography>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}
