import React from 'react';
interface CategoryFilterProps {
    categories: string[];
    activeFilter: string;
    onFilterChange: (filter: string) => void;
}

// Define valid category types using a union type
type ValidCategory = 'All Categories' | 'Process' | 'Safety' | 'General' | 'Conditions' | 'Cost' | 'Shaving' | 'Waxing';

// Define the priority map type
type PriorityMap = {
    readonly [K in ValidCategory]: number;
};

// Define the priority order as a constant
const orderPriority: PriorityMap = {
    'All Categories': 0, //All Categories are added manually in the code, its not coming from the API
    Process: 1,
    Safety: 2,
    General: 3,
    Conditions: 4,
    Cost: 5,
    Shaving: 6,
    Waxing: 7,
} as const;

/**
 * Sorts categories according to a predefined order
 * @param categories - Array of category names to sort
 * @returns Sorted array of categories
 */
const sortCategories = (categories: string[]): string[] => {
    return [...categories].sort((a, b) => {
        const priorityA = a in orderPriority ? orderPriority[a as ValidCategory] : Infinity;
        const priorityB = b in orderPriority ? orderPriority[b as ValidCategory] : Infinity;

        return priorityA - priorityB;
    });
};

export const CategoryFilter: React.FC<CategoryFilterProps> = ({ categories, activeFilter, onFilterChange }) => {
    const sortedCategories = sortCategories(categories);
    return (
        <div className="flex gap-4 overflow-x-auto min-w-max pb-3">
            {sortedCategories.map((category, index) => (
                <button
                    key={`${category}-${index}`}
                    onClick={() => onFilterChange(category)}
                    className={`
                        px-[15px] py-[6px] 
                        border-none 
                        rounded-[10px]
                        hover:bg-milan-grey 
                        focus:bg-milan-secondary-blue
                        transition-colors
                        ${activeFilter === category ? 'bg-milan-secondary-blue' : 'bg-milan-off-white'}
                    `}
                    role="radio"
                    aria-checked={activeFilter === category}
                    aria-label={`Filter by ${category}`}
                    tabIndex={0}
                >
                    {category}
                </button>
            ))}
        </div>
    );
};
