import { Builder } from '@builder.io/react';
import { BUILDER_DIVIDER_NAMES } from '../BuilderComponentsName';
import { DynamicHeroBuilder } from '../components/hero/DynamicHero.builder';

Builder.registerComponent(DynamicHeroBuilder, {
    name: BUILDER_DIVIDER_NAMES.DynamicHero,
    friendlyName: 'Dynamic Hero',
    image: 'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0',
    inputs: [
        {
            name: 'heroDesktopImage',
            friendlyName: 'Hero Desktop Image',
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg', 'webp'],
            required: true,
            defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2F117a302d0b594b9db3f43d9670a1a29c%2F62a3eb6019a94d79a55419c033828b57',
        },
        {
            name: 'heroDesktopImageAlt',
            type: 'string',
            friendlyName: 'Hero Desktop Image Alt',
            defaultValue: 'Hero Desktop Image',
            required: true,
        },
        {
            name: 'heroTabletImage',
            friendlyName: 'Hero Tablet Image',
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg', 'webp'],
            required: true,
            defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2F117a302d0b594b9db3f43d9670a1a29c%2F165758bf5b714447998cdc3c73c9e7c7',
        },
        {
            name: 'heroTabletImageAlt',
            type: 'string',
            friendlyName: 'Hero Tablet Image Alt',
            defaultValue: 'Hero Tablet Image',
            required: true,
        },
        {
            name: 'heroMobileImage',
            friendlyName: 'Hero Mobile Image',
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg', 'webp'],
            required: true,
            defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2F117a302d0b594b9db3f43d9670a1a29c%2F165758bf5b714447998cdc3c73c9e7c7',
        },
        {
            name: 'heroMobileImageAlt',
            type: 'string',
            friendlyName: 'Hero Mobile Image Alt',
            defaultValue: 'Hero Mobile Image',
            required: true,
        },
        {
            name: 'typefaceLogo',
            friendlyName: 'Logo Image',
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg', 'webp'],
            required: false,
            defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2F117a302d0b594b9db3f43d9670a1a29c%2F1385ba94b57c45d29e955fb1417c1494',
        },
        {
            name: 'logoImageAlt',
            type: 'string',
            friendlyName: 'Logo Image Alt',
            defaultValue: 'Logo Image',
        },
        {
            name: 'heading',
            type: 'string',
            defaultValue: 'Laser Hair Removal Specials',
            required: true,
        },
        {
            name: 'headingColor',
            friendlyName: 'Desktop Heading Color',
            type: 'string',
            enum: ['milan-text-primary', 'milan-text-white', 'milan-text-navy', 'milan-text-black'],
            defaultValue: 'milan-text-primary',
            required: true,
            helperText: 'Select the color for the heading text on desktop (primary=blue)',
        },
        {
            name: 'headingColorMobileAndTablet',
            friendlyName: 'Mobile/Tablet Heading Color',
            required: true,
            type: 'string',
            enum: ['milan-text-primary', 'milan-text-white', 'milan-text-navy', 'milan-text-black'],
            defaultValue: 'milan-text-primary',
            helperText: 'Select the color for the heading text on mobile/tablet (primary=blue)',
        },
        {
            name: 'richTextContent',
            friendlyName: 'Text Content',
            type: 'richText',
            required: true,
            defaultValue: `<p>Hey, boo! It’s time to ghost your razor, trick unwanted hair, and treat yourself to 50% OFF unlimited laser hair removal plus 1 select body area FREE* — no bones about it. Our&nbsp;<a href="https://qa.milanlaser.com/specials/#guarantee" rel="noopener noreferrer" style="color: rgb(0, 136, 171);"><strong>exclusive Unlimited Package™</strong></a><span style="color: rgb(0, 136, 171);">&nbsp;</span>comes with every purchase and includes FREE touch-ups, so you’re covered for life at our 380+ locations nationwide.</p><p><strong>Scary Not Hairy Bonus:</strong>&nbsp;Receive a&nbsp;<strong>FREE TREATMENT*</strong>&nbsp;when you complete a complimentary consultation this month.</p><p>Grab your skeleton crew and visit your&nbsp;<a href="https://qa.milanlaser.com/locations/" rel="noopener noreferrer" style="color: rgb(0, 136, 171);"><strong>local Milan Laser</strong></a>&nbsp;by&nbsp;<strong>October 31</strong>&nbsp;to get half-off unlimited laser hair removal and 1 FREE body area!</p>`,
        },
        {
            name: 'buttonLabel',
            friendlyName: 'Button Label',
            type: 'string',
            defaultValue: 'Book My Free Consult',
        },
        {
            name: 'buttonLink',
            friendlyName: 'Button Link',
            type: 'string',
            defaultValue: '#consult',
        },
        {
            name: 'disclaimerLinkText',
            friendlyName: 'Disclaimer Link Text',
            type: 'longText',
            defaultValue: 'Stay up to date with special discounts and sales by subscribing to our email list.',
            helperText: 'Text for the disclaimer link that opens the modal',
            required: true,
        },
        {
            name: 'disclaimerTextPromise',
            friendlyName: 'Disclaimer Text Promise',
            type: 'longText',
            defaultValue: "We promise we'll never spam you or sell your information.",
            helperText: 'Second part of the disclaimer text, this could be a promise or a statement',
            required: true,
        },
        {
            name: 'disclaimerColor',
            friendlyName: 'Desktop Disclaimer Color',
            type: 'string',
            enum: ['milan-text-primary', 'milan-text-white', 'milan-text-navy', 'milan-text-black'],
            defaultValue: 'milan-text-primary',
            required: true,
            helperText: 'Select the color for the disclaimer text on desktop (primary=blue)',
        },
        {
            name: 'disclaimerColorMobileAndTablet',
            friendlyName: 'Mobile/Tablet Disclaimer Color',
            required: true,
            type: 'string',
            enum: ['milan-text-primary', 'milan-text-white', 'milan-text-navy', 'milan-text-black'],
            defaultValue: 'milan-text-primary',
            helperText: 'Select the color for the disclaimer text on mobile/tablet (primary=blue)',
        },
        {
            name: 'isHeroImgOnRight',
            friendlyName: 'Is Hero Image on Right?',
            type: 'boolean',
            defaultValue: false,
        },
        {
            name: 'footerText',
            friendlyName: 'Footer Text',
            type: 'string',
            defaultValue: '*Call or visit clinic for details.',
        },
        {
            name: 'footerTextColor',
            friendlyName: 'Desktop Footer Text Color',
            type: 'string',
            enum: ['milan-text-primary', 'milan-text-white', 'milan-text-navy', 'milan-text-black'],
            defaultValue: 'milan-text-primary',
            required: true,
            helperText: 'Select the color for the footer text on desktop (primary=blue)',
        },
        {
            name: 'footerTextColorMobileAndTablet',
            friendlyName: 'Mobile/Tablet Footer Text Color',
            required: true,
            type: 'string',
            enum: ['milan-text-primary', 'milan-text-white', 'milan-text-navy', 'milan-text-black'],
            defaultValue: 'milan-text-primary',
            helperText: 'Select the color for the footer text on mobile/tablet (primary=blue)',
        },
        {
            name: 'desktopImageBackgroundColor',
            friendlyName: 'Desktop Image Background',
            type: 'color',
            defaultValue: '#FFFFFF',
            helperText: 'Background color for the image section on desktop view',
        },
        {
            name: 'desktopBackgroundColor',
            friendlyName: 'Desktop Content Background',
            type: 'color',
            defaultValue: '#FFFFFF',
            helperText: 'Background color for entire section on desktop view',
        },
        {
            name: 'contentBackgroundColor',
            friendlyName: 'Mobile/Tablet Content Background',
            type: 'color',
            defaultValue: '#FFFFFF',
            helperText: 'Background color for content area on mobile and tablet only',
        },
    ],
});
