import React from 'react';
import { Typography } from '../../../../../components/Typography/Typography';
import { BREAKPOINTS } from '../../../../../utils/constants';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    totalItems: number;
    itemsPerPage: number;
    onPageChange: (page: number) => void;
    getPageNumbers: () => number[];
    getPageNumbersForMobile: () => number[];
    minWidth?: number;
    showingText?: string;
    previousLabel?: string;
    nextLabel?: string;
}

export function Pagination({
    currentPage,
    totalPages,
    totalItems,
    itemsPerPage,
    onPageChange,
    getPageNumbers,
    getPageNumbersForMobile,
    minWidth = 0,
    showingText = 'Showing',
    previousLabel = 'Previous',
    nextLabel = 'Next',
}: PaginationProps) {
    // const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(currentPage * itemsPerPage, totalItems);

    // Get the page numbers based on the current breakpoint
    const pageNumbers = minWidth <= BREAKPOINTS.md ? getPageNumbersForMobile() : getPageNumbers();

    return (
        <div className="flex flex-col lg:flex-row justify-between items-center w-full text-gray-700 gap-2">
            <div className="flex items-center space-x-[30px]">
                {/* Previous button */}
                <button
                    onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`inline-flex items-center border-none text-sm bg-transparent ${
                        currentPage === 1 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-700 hover:text-gray-900'
                    }`}
                    aria-label="Previous"
                >
                    <div className="flex items-center">
                        <svg
                            className="w-4 h-4 mr-1"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="30"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                        </svg>
                        <Typography
                            variant={'pDefault'}
                            tag="p"
                            color={`${
                                currentPage === 1
                                    ? 'text-gray-300 cursor-not-allowed'
                                    : 'text-milan-navy hover:text-gray-900'
                            }`}
                        >
                            {previousLabel}
                        </Typography>
                    </div>
                </button>

                {/* Page numbers */}
                <div className="flex items-center space-x-[30px]">
                    {pageNumbers.map((page, index) => {
                        // Add ellipsis if there's a gap in page numbers
                        if (index > 0 && pageNumbers[index] - pageNumbers[index - 1] > 1) {
                            return (
                                <React.Fragment key={`ellipsis-${index}`}>
                                    <span className="px-2 py-1 text-sm text-gray-500">...</span>
                                    <button
                                        key={page}
                                        onClick={() => onPageChange(page)}
                                        className={`w-7 h-7 flex items-center justify-center rounded-full text-sm border-none ${
                                            currentPage === page
                                                ? 'bg-milan-turquoise text-white' // Selected page with turquoise background
                                                : 'bg-milan-white hover:bg-milan-turquoise-light'
                                        }`}
                                    >
                                        {page}
                                    </button>
                                </React.Fragment>
                            );
                        }

                        return (
                            <button
                                key={page}
                                onClick={() => onPageChange(page)}
                                className={`w-7 h-7 flex items-center justify-center rounded-full text-sm border-none ${
                                    currentPage === page
                                        ? 'bg-milan-turquoise text-white'
                                        : 'bg-milan-white hover:bg-milan-turquoise-light'
                                }`}
                            >
                                {page}
                            </button>
                        );
                    })}
                </div>

                {/* Next button */}
                <button
                    onClick={() => currentPage < totalPages && onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className={`inline-flex items-center border-none text-sm bg-transparent ${
                        currentPage === totalPages
                            ? 'text-gray-300 cursor-not-allowed'
                            : 'text-gray-700 hover:text-gray-900'
                    }`}
                    aria-label="Next"
                >
                    <div className="flex items-center">
                        <Typography
                            variant={'pDefault'}
                            tag="p"
                            color={`${
                                currentPage === totalPages
                                    ? 'text-gray-300 cursor-not-allowed'
                                    : 'text-milan-navy hover:text-gray-900'
                            }`}
                        >
                            {nextLabel}
                        </Typography>
                        <svg
                            className="w-4 h-4 ml-1"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                    </div>
                </button>
            </div>

            {/* Items counter */}
            <div className="hidden md:block md:mt-4 lg:mt-0 lg:ml-[50px] text-sm text-gray-600">
                <Typography variant={'pDefault'} tag="p">
                    {showingText} {endItem} of {totalItems}
                </Typography>
            </div>
        </div>
    );
}
