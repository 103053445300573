// src/components/builder/types/Typography.ts
import { HTMLAttributes, ReactNode } from 'react';

export type TypographyVariant =
    | 'h1Hero'           // Hero header
    | 'h1SmallTitle'     // Small Hero Header 
    | 'h2SubheadLg'      // Large subheader
    | 'h2SubheadSm'      // Small subheader
    | 'h3SubpointLg'     // Large subpoint
    | 'h3SubpointSm'     // Small subpoint
    | 'h4Footer'         // Footer header
    | 'h5Subheader'      // Subhead
    | 'h6FormSubheader'  // Form subhead
    | 'pDefault'         // Default paragraph
    | 'pSubhead'         // Paragraph subhead
    | 'pSubheadLg'         // Paragraph subhead Large
    | 'pOversized'      // Oversized paragraph
    | 'milanFp'          // Fine print text
    | 'strong'           // Bold text
    | 'em'             // Emphasized/italic text
    | "p-PullQuote";


    export enum MilanTextColor {
        Primary = 'milan-text-primary',
        White = 'milan-text-white',
        Navy = 'milan-text-navy',
        Black = 'milan-text-black',
        Turquoise = 'milan-text-tur',
        // add more if needed
      }
export interface TypographyProps {
    variant: TypographyVariant;
    tag: 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'div' | 'label' | 'button';
    children: ReactNode;
    className?: HTMLAttributes<HTMLElement> | string;
    color?: MilanTextColor | string; //there are cases where we need to pass a custom color
}