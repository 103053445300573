import React, { useContext, useState } from 'react';
import { LPUnlimitedPackage } from '../../../lp';
import { SignUpModal } from '../../../shared';
import { GlobalSiteDataContext } from '../../../../context';

export default function ULPackage2Builder() {
    const [showSignUpModal, setShowSignUpModal] = useState(false);
    const siteData = useContext(GlobalSiteDataContext);

    return (
        <div id="social-page">
            {showSignUpModal && <SignUpModal {...{ siteData, showSignUpModal, setShowSignUpModal }} />}
            <LPUnlimitedPackage setShowSignUpModal={setShowSignUpModal} />
        </div>
    );
}
