import { Builder } from '@builder.io/react';
import { BUILDER_DIVIDER_NAMES } from '../BuilderComponentsName';
import { PopularBlogsBuilder } from '../components/blogs/PopularBlogs';
Builder.registerComponent(PopularBlogsBuilder, {
   name: BUILDER_DIVIDER_NAMES.PopularBlogs,
   friendlyName: 'Popular Blogs',
   inputs: [
      {
         name: 'heading',
         type: 'string',
         defaultValue: 'Popular Blogs',
         required: false,
         helperText: 'Enter heading (max 50 characters)',
         regex: {
             pattern: '^.{1,50}$',
             message: 'Heading must not exceed 50 characters'
         }
      },
      {
         name: 'headingColor',
         friendlyName: 'Heading Color',
         type: 'string',
         enum: ['milan-text-primary', 'milan-text-white', 'milan-text-navy', 'milan-text-black', 'milan-text-md-grey'],
         defaultValue: 'milan-text-md-grey',
         required: false,
         helperText: 'Select the color for the heading text (primary=blue)',
      },
   ],
});
