import React, { useContext } from 'react';
import { GlobalSiteDataContext } from '../../../../context';
import { LessThanTenDivider, SchemaRating } from '../../../specials';

export function RatingsAndLipsOrUnderarmDivider() {
    const siteData = useContext(GlobalSiteDataContext);

    return (
        <>
            {siteData.globalData.avg_rating && <SchemaRating globalData={siteData.globalData} />}
            <LessThanTenDivider />
        </>
    );
}
