import React from 'react';
import { Typography } from '../../../Typography/Typography';

interface BlogTextSectionProps {
    headline: string;
    content: string;
}

export function BlogTextSection({ headline, content }: BlogTextSectionProps) {
    return (
        <div className="container px-2">
            <div className="xl:w-[75%]" id="section-with-text-only">
                <Typography variant="h2SubheadLg" color="milan-text-primary" tag="h2" className="mb-6">
                    {headline}
                </Typography>

                <div
                    className={`
                    [&>p]:font-open-sans [&>p]:font-regular [&>p]:tracking-paragraph
                    [&>p]:text-paragraph-mobile [&>p]:leading-paragraph-mobile
                    md:[&>p]:text-paragraph-desktop md:[&>p]:leading-paragraph-desktop
                    [&>p]:mb-4 last:[&>p]:mb-0
                    [&>h2]:font-open-sans [&>h2]:font-regular [&>h2]:capitalize
                    [&>h2]:text-paragraph-subhead [&>h2]:leading-subheader-paragraph
                    [&>h2]:mb-4 [&>h2]:mt-8 first:[&>h2]:mt-0 mb-12
                `}
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            </div>
        </div>
    );
}
