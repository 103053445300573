import React, { JSX } from 'react';
import { PullQuoteProps } from '../../types/blogs/blogs';
import { Typography } from '../../../Typography/Typography';

export function PullQuoteBuilder({
    quoteText,
    backgroundColor = 'rgba(194, 194, 194, 0.10)',
    textColor = 'milan-text-tur',
}: PullQuoteProps): JSX.Element {
    return (
        <div className="!p-4 md:p-50" id="pull-quote">
            <div className="container-fluid">
                <div className="container flex justify-center py-50 md:px-50">
                    <div
                        className="relative px-6 py-50 lg:px-150  rounded-xl w-full max-w-[1200px] "
                        style={{ backgroundColor }}
                    >
                        <img
                            src="https://cdn.builder.io/api/v1/image/assets%2F10266dac478d4c768f6b6fbe586ae30e%2Fa9500308536c401288922d0a57a824e0"
                            alt="Quote mark"
                            className="absolute -top-11 lg:-top-8 w-20 h-20 -left-[10px] lg:left-[94px] lg:w-[96px] lg:h-[96px]"
                        />
                        <Typography
                            variant="p-PullQuote"
                            color={textColor}
                            className="text-left break-words m-0"
                            tag="p"
                        >
                            {quoteText}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
}
