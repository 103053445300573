// This is duplicate because of the only commonJS module supported by netlify functions at this moment.
// Getting error when using ES6 module import.
const builderModels = {
    page: 'page',
    landingPage: 'landing-page',
    author: 'author-page',
    blog: 'blog-page',
    symbol: 'symbol',
    promotionsBanners: 'promotions-banners',
    builderUsers: 'builder-users',
};

// USING REST API

class BuilderAPIClient {
    /**
     * @param {string} apiKey - The Builder.io API key
     * @param {string} model - The Builder.io model name
     */
    constructor(apiKey, model) {
        if (!apiKey) {
            throw new Error('API key is required');
        }
        if (!model) {
            throw new Error('Model name is required');
        }

        this.apiKey = apiKey;
        this.model = model;
        this.baseURL = 'https://cdn.builder.io/api/v3';
        this.defaultHeaders = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };
    }

    /**
     * Fetches content from Builder.io API
     * @param {Object} queryParams - Additional query parameters
     * @returns {Promise<Object>} - The response data
     * @throws {Error} - If the request fails
     */
    //Fetch builder users, you can add more methods here for different models
    async getBuilderUsers(queryParams = {}) {
        try {
            const params = new URLSearchParams({
                apiKey: this.apiKey,
                ...queryParams,
            });

            const response = await fetch(`${this.baseURL}/content/${this.model}?${params}`, {
                method: 'GET',
                headers: this.defaultHeaders,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error(`Error fetching ${this.model}:`, error);
            throw error;
        }
    }

    async getBuilderPageData(queryParams = {}) {
        try {
            const params = new URLSearchParams({
                apiKey: this.apiKey,
                ...queryParams,
            });

            const response = await fetch(`${this.baseURL}/content/${this.model}?${params}`, {
                method: 'GET',
                headers: this.defaultHeaders,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error(`Error fetching ${this.model}:`, error);
            throw error;
        }
    }

    async getBuilderModelContent(queryParams = {}) {
        try {
            const params = new URLSearchParams({
                apiKey: this.apiKey,
                ...queryParams,
            });

            const response = await fetch(`${this.baseURL}/content/${this.model}?${params}`, {
                method: 'GET',
                headers: this.defaultHeaders,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error(`Error fetching ${this.model}:`, error);
            throw error;
        }
    }
}

async function getAllBuilderUser() {
    const builderClient = new BuilderAPIClient(process.env.GATSBY_BUILDER_IO, builderModels.builderUsers);
    try {
        const users = await builderClient.getBuilderUsers();
        return users;
    } catch (error) {
        console.error('Unable to fetch list of builder users:', error);
    }
}

async function getBuilderUser(userId) {
    // Get list of all builder users
    const users = await getAllBuilderUser();
    // Get the user by userId
    if (users && users?.results?.length > 0) {
        const user = users?.results?.find((user) => user?.data?.userId === userId);
        return user;
    }
    return null;
}

async function getBuilderBlogPageData() {
    const builderClient = new BuilderAPIClient(process.env.GATSBY_BUILDER_IO, builderModels.blog);
    try {
        const pageData = await builderClient.getBuilderPageData();
        return pageData;
    } catch (error) {
        console.error(`Unable to fetch list of blog pages:`, error);
    }
}

async function getBuilderModelData(model, queryParams = {}) {
    const builderClient = new BuilderAPIClient(process.env.GATSBY_BUILDER_IO, model);
    try {
        const pageData = await builderClient.getBuilderModelContent(queryParams);
        return pageData;
    } catch (error) {
        console.error(`Unable to fetch list of blog pages:`, error);
    }
}

// using module.exports to support commonJS modules
module.exports = { BuilderAPIClient, getAllBuilderUser, getBuilderUser, getBuilderBlogPageData, getBuilderModelData };
