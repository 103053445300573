import { Builder } from '@builder.io/react';
import { BUILDER_DIVIDER_NAMES } from '../BuilderComponentsName';
import CreditApproval from '../components/sections/CreditApproval.Builder';

Builder.registerComponent(CreditApproval, {
    name: BUILDER_DIVIDER_NAMES.CreditApproval,
    defaults: {
        bindings: {
            'component.options.globalData': 'state.siteData.globalData',
        },
    },
});
