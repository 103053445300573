import React, { useContext } from 'react';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import resultsSvg from '../../images/homedevice/cardicon/results.svg';
import technologySvg from '../../images/homedevice/cardicon/technology.svg';
import safetySvg from '../../images/homedevice/cardicon/Safety.svg';
import compatibilitySvg from '../../images/homedevice/cardicon/Compatibility.svg';
import expediencySvg from '../../images/homedevice/cardicon/Expediency.svg';
import { Link } from 'gatsby';

function ContextAwareToggle({ children, eventKey, callback, className }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <div
            onClick={decoratedOnClick}
            role="button"
            tabIndex="0"
            onKeyDown={(e) => e.key === 'Enter' && decoratedOnClick}
            className={className}
        >
            {children}

            {isCurrentEventKey ? <FontAwesomeIcon icon={faCircleMinus} /> : <FontAwesomeIcon icon={faCirclePlus} />}
        </div>
    );
}

export function HomeDeviceCard({}) {
    return (
        <section id="home-device-card" className="py-4">
            <div className="container">
                <Accordion className="row g-3 pt-4">
                    <div className="col-md-6">
                        <Card>
                            <div className="py-3">
                                <Card.Header>
                                    <ContextAwareToggle
                                        className="d-flex justify-content-between align-items-center"
                                        eventKey="0"
                                    >
                                        <div>
                                            <img src={technologySvg} alt="Home Device - Technology" />
                                            <span className="card-title">Technology</span>
                                        </div>
                                    </ContextAwareToggle>
                                    <p className="card-text pt-4">
                                        Most DIY devices <strong>do not use an actual laser</strong> but intense pulsed
                                        light (IPL). Some other hand-held devices use diode lasers. The mechanism is
                                        similar to laser hair removal, but it’s less powerful and takes many more
                                        sessions for results.
                                    </p>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <ul className="list-style-disc ps-2">
                                            <li>
                                                Milan Laser uses the Candela GentleMax Pro, the gold standard in laser
                                                hair removal
                                            </li>
                                            <li>
                                                Our lasers work alongside air-cooling Chiller technology for the most
                                                comfortable treatments possible
                                            </li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </div>
                        </Card>
                    </div>

                    <div className="col-md-6">
                        <Card>
                            <div className="py-3">
                                <Card.Header>
                                    <ContextAwareToggle
                                        className="d-flex justify-content-between align-items-center"
                                        eventKey="1"
                                    >
                                        <div>
                                            <img src={resultsSvg} alt="Home Device - Result" />
                                            <span className="card-title">Results</span>
                                        </div>
                                    </ContextAwareToggle>
                                    <p className="card-text pt-4">
                                        Some users see minimal results with at-home devices, which take many sessions
                                        and are rarely permanent. DIY devices' unfocused, multi-wavelength light leads
                                        to patchy, inconsistent results.{' '}
                                        <strong>
                                            <a
                                                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC10218747/"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                According to a study
                                            </a>
                                        </strong>
                                        , IPL users saw only a 46-52% reduction in unwanted hair.
                                    </p>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <div>
                                            <ul className="list-style-disc ps-2">
                                                <li>
                                                    <span>
                                                        Professional laser hair removal precisely targets pigment in the
                                                        hair follicles
                                                    </span>
                                                </li>
                                                <li>
                                                    Most Milan Laser Hair Removal clients see up to 95% hair reduction
                                                    within 7 to 10 sessions.
                                                </li>
                                                <li>
                                                    We offer unlimited treatments for life with every purchase — get
                                                    treated at any of our 350+ locations nationwide, any time
                                                </li>
                                            </ul>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </div>
                        </Card>
                    </div>

                    <div className="col-md-6">
                        <Card>
                            <div className="py-3">
                                <Card.Header>
                                    <ContextAwareToggle
                                        className="d-flex justify-content-between align-items-center"
                                        eventKey="2"
                                    >
                                        <div>
                                            <img src={safetySvg} alt="Home Device - Safety" />
                                            <span className="card-title">Safety</span>
                                        </div>
                                    </ContextAwareToggle>
                                    <p className="card-text pt-4">
                                        Remember, it’s DIY laser — <strong>you’re doing it yourself.</strong> You have
                                        to follow instructions and be diligent. There’s no medical oversight if
                                        something goes wrong. At-home devices{' '}
                                        <a
                                            href="https://onlinelibrary.wiley.com/doi/10.1002/lsm.23650"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            may carry a high burn risk
                                        </a>
                                        , particularly for those with darker skin tones or when used on or around
                                        tattoos, including removed tattoos. Most home devices Diode lasers don’t include
                                        eye protection and{' '}
                                        <a
                                            href="https://pubmed.ncbi.nlm.nih.gov/38152153/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            can cause ocular complications
                                        </a>
                                        .
                                    </p>
                                </Card.Header>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        <p>
                                            DIY laser hair removal devices can cause complications such as burning and
                                            blistering for darker skin tones.{' '}
                                        </p>
                                        <ul className="list-style-disc ps-2">
                                            <li>
                                                Highly trained medical professionals perform all treatments at Milan
                                                Laser so the risks are minimized
                                            </li>
                                            <li>
                                                Clients get a tailored treatment plan based on hair color, skin tone,
                                                tattoos, etc.
                                            </li>
                                            <li>
                                                Milan Laser has industry-leading safety protocols in place for before,
                                                during, and after treatments
                                            </li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </div>
                        </Card>
                    </div>

                    <div className="col-md-6">
                        <Card>
                            <div className="py-3">
                                <Card.Header>
                                    <ContextAwareToggle
                                        className="d-flex justify-content-between align-items-center"
                                        eventKey="3"
                                    >
                                        <div>
                                            <img src={compatibilitySvg} alt="Home Device - Compatibility" />
                                            <span className="card-title">Compatibility</span>
                                        </div>
                                    </ContextAwareToggle>
                                    <p className="card-text pt-4">
                                        Did you know some at-home devices shut off if your skin tone isn’t “compatible”?
                                        At-home devices often don’t work well on darker skin tones because they target
                                        pigment in the hair. Darker skin tones have more melanin, which can absorb the
                                        laser energy intended for the hair follicles.
                                    </p>
                                </Card.Header>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        <ul className="list-style-disc ps-2">
                                            <li>
                                                Professional laser hair removal at Milan works on all skin tones safely
                                                and effectively
                                            </li>
                                            <li>
                                                Milan Laser uses advanced laser technology and tailored treatment
                                                settings adjusted to suit individual skin and hair types
                                            </li>
                                            <li>
                                                Our highly trained experts assess and adjust settings as needed to
                                                ensure the best treatments and results
                                            </li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </div>
                        </Card>
                    </div>

                    <div className="col-md-6">
                        <Card>
                            <div className="py-3">
                                <Card.Header>
                                    <ContextAwareToggle
                                        className="d-flex justify-content-between align-items-center"
                                        eventKey="4"
                                    >
                                        <div>
                                            <img src={expediencySvg} alt="Home Device - Expediency" />
                                            <span className="card-title">Expediency</span>
                                        </div>
                                    </ContextAwareToggle>
                                    <p className="card-text pt-4">
                                        Imagine using a small DIY laser to cover every inch of your legs. These devices
                                        have limitations: frequent recharging, short cords, restricted reach, and
                                        limited treatment area size.  Their low intensity makes it easy to overlook
                                        spots, particularly dormant follicles that aren't visible, often resulting in
                                        incomplete results.
                                    </p>
                                </Card.Header>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>
                                        <ul className="list-style-disc ps-2">
                                            <li>
                                                Our licensed medical staff maps out a precise treatment grid across the
                                                full target area, ensuring every inch of skin is covered and no spots
                                                are missed
                                            </li>
                                            <li>
                                                Professional laser hair removal can be done on multiple body areas in 30
                                                minutes or less
                                            </li>
                                            <li>
                                                Milan Laser Hair Removal’s experts treat most areas head to toe once
                                                every five weeks — no carving out extra time or missing hard-to-reach
                                                areas!
                                            </li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </div>
                        </Card>
                    </div>
                </Accordion>
            </div>
            <div className="my-4 text-center">
                <Link className="milan-btn milan-primary-btn" to="/process/">
                    Learn More
                </Link>
            </div>
        </section>
    );
}

export default HomeDeviceCard;
