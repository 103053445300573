import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { GlobalSiteDataContext } from '../../context';

const splitByPipe = (text) => {
    if (text && typeof text !== 'string') {
        throw new Error('Input must be a string');
    } else {
        const bannerTextSplitArray = text?.split('|')?.map((part) => part.trim());
        const specialsBannerLinkTextFirstPart = bannerTextSplitArray[0];
        const specialsBannerTextSecondPart = bannerTextSplitArray[1];

        return { specialsBannerLinkTextFirstPart, specialsBannerTextSecondPart };
    }
};

const SpecialsBanner = () => {
    const siteData = useContext(GlobalSiteDataContext);
    const { isBuilderDataLoading } = siteData;
    const specialsBannerText = siteData?.promotionalBannersTextData?.specialsBanner;

    const [formattedSpecialsBannerText, setFormattedSpecialsBannerText] = useState(null);

    useEffect(() => {
        if (specialsBannerText) {
            const formatBannerText = splitByPipe(specialsBannerText);
            setFormattedSpecialsBannerText(formatBannerText);
        }
    }, [specialsBannerText]);

    return (
        <Link to="/specials/" className="specials-banner milan-text-white">
            <span className="text-uppercase">
                {!isBuilderDataLoading ? (
                    <>
                        <strong>{formattedSpecialsBannerText?.specialsBannerLinkTextFirstPart}</strong>{' '}
                        <span>{formattedSpecialsBannerText?.specialsBannerTextSecondPart}</span>
                    </>
                ) : (
                    <></>
                )}
            </span>
        </Link>
    );
};

export default SpecialsBanner;
