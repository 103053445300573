import { Builder } from '@builder.io/react';
import { BUILDER_BLOG_COMPONENTS_NAMES } from '../BuilderComponentsName';
import { SectionWithImageBuilder } from '../components/blogs/SectionWithImage.builder';

Builder.registerComponent(SectionWithImageBuilder, {
    name: BUILDER_BLOG_COMPONENTS_NAMES.SectionWithImage,
    friendlyName: 'Section With Image',
    inputs: [
        {
            name: 'headline',
            type: 'text',
            defaultValue: 'Factors Affecting Longevity',
            required: true,
            helperText: 'Enter heading (max 100 characters)',
            regex: {
                pattern: '^.{1,100}$',
                message: 'Heading must not exceed 100 characters'
            }
        },
        {
            name: 'headingColor',
            friendlyName: 'Heading Color',
            type: 'string',
            enum: ['milan-text-primary', 'milan-text-white', 'milan-text-navy', 'milan-text-black'],
            defaultValue: 'milan-text-primary',
            required: true,
            helperText: 'Select the color for the heading text (primary=blue)',
        },
        {
            name: 'richTextContent',
            friendlyName: 'Text Content',
            type: 'richText',
            required: true,
            defaultValue: `
            <p>There are a number of reasons why laser hair removal isn't always permanent. The most prominent factors include but aren't limited to:</p>
            <ul>
                <li>Hair Type and Color: Laser hair removal works best for coarse, dark hair, but obviously not everyone has that hair color. Clients who have finer hair may require more sessions and touch ups to maintain desired results.</li>
                <li>Skin Tone: A lighter skin tone with darker hair color yields the best contrast for the laser, maximizing effectiveness. Laser hair removal can still be performed safely on other skin tones, but it may require adjusted settings and potentially more frequent touch-up sessions.</li>
                <li>Hormonal Fluctuations: Changes in hormone levels throughout a person's life can stimulate hair growth. Pregnancy, puberty, and certain medications are some of the most common reasons for increased hair growth, which may necessitate additional touch-up sessions after laser hair removal treatment.</li>
            </ul>
        `,
        },
        {
            name: 'sectionImage',
            friendlyName: 'Section Image',
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'webp'],
            required: true,
            defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2F10266dac478d4c768f6b6fbe586ae30e%2Fd834a7697a26450b83a9b5795a832b9f',
        },
        {
            name: 'sectionImageAlt',
            type: 'string',
            friendlyName: 'Section Image Alt Text',
            defaultValue: 'Section Image',
            required: true,
        },
        {
            name: 'isImageOnRight',
            friendlyName: 'Is Image on Right?',
            type: 'boolean',
            defaultValue: true,
        },
    ],
});
