import React from 'react';
import { BlogData } from 'components/builder/types/blogs/blogHero';
import { Typography } from '../../../../../components/Typography/Typography';
import { format } from 'date-fns';
import { AuthorData } from 'components/builder/types';
import { Link } from 'gatsby';
import { getAuthorUrl } from '../../../../../components/builder/utils/blog';

type CardProps = {
    blogData: BlogData;
    // isDataFetching: boolean;
    activeFilter: string;
    onAuthorClick: (authorName: string) => void;
    onFilterChange: (filter: string) => void;
    authorData: AuthorData;
};

export function Card({ blogData, activeFilter, authorData, onAuthorClick, onFilterChange }: CardProps) {
    const { headline, image, minuteRead, publishedDate, categories, imageAlt, pageUrl } = blogData;

    const formattedDate = publishedDate && format(new Date(publishedDate), 'MMM dd yyyy');

    const authorUrl = getAuthorUrl(authorData?.fullName || '');

    const handleFilterSelect = (filter: string) => {
        // If the filter is already active, return early
        if (filter === activeFilter) return;
        onFilterChange(filter); // Only call this if the filter has changed
    };

    return (
        <div className="grid grid-cols-1 gap-6 py-[14px]">
            <div className="flex flex-col gap-[30px] w-full">
                {/* image */}
                <button
                    className="border-none outline-none bg-transparent p-0 m-0"
                    aria-label={`Read more about ${headline}`}
                    onClick={(e) => {
                        e.preventDefault();
                        const url = `/blog${pageUrl}`;

                        if (typeof window !== 'undefined') {
                            window.location.href = url;
                        }
                    }}
                >
                    <img
                        src={image}
                        alt={imageAlt}
                        className="w-full max-h-[247px] rounded-md object-cover hover:opacity-70 cursor-pointer"
                    />
                </button>
                <div className="flex flex-col gap-4">
                    {/* categories as pills */}
                    <div className="flex gap-2 flex-wrap w-full">
                        {categories.map((category, index) => (
                            <button
                                key={index}
                                className="px-[15px] py-[5px] border-none focus:bg-milan-secondary-blue hover:opacity-70 bg-milan-secondary-blue rounded-[10px] max-h-[28px] flex items-center justify-center"
                                aria-label={category}
                                onClick={() => handleFilterSelect(category)}
                            >
                                <span className="text-milan-navy text-[12px] leading-[18px] font-normal text-center tracking-[0.1px]">
                                    {category}
                                </span>
                            </button>
                        ))}
                    </div>
                    {/* publishedDate.minutesToRead */}
                    <div className="flex items-center">
                        <Typography variant={'h4Footer'} tag={'span'} className={'m-0'} color={'milan-text-tur'}>
                            {formattedDate}
                        </Typography>
                        <Typography variant={'h4Footer'} tag={'span'} className={'m-[2px]'} color={'milan-text-tur'}>
                            •
                        </Typography>
                        <Typography variant={'h4Footer'} tag={'span'} className={'m-0'} color={'milan-text-tur'}>
                            {minuteRead} Minute Read
                        </Typography>
                    </div>

                    {/* Headline */}
                    <button
                        className="border-none outline-none bg-transparent p-0 m-0 text-start"
                        aria-label={`Read more about ${headline}`}
                        onClick={(e) => {
                            e.preventDefault();
                            const url = `/blog${pageUrl}`;

                            if (typeof window !== 'undefined') {
                                window.location.href = url;
                            }
                        }}
                    >
                        <Typography variant={'h2SubheadSm'} tag={'h2'} color="milan-text-primary">
                            {headline}
                        </Typography>
                    </button>

                    {/* Author */}
                    <>
                        <div className="flex items-center gap-3">
                            {/* Avatar Image */}
                            <div
                                className="w-10 h-10 rounded-full overflow-hidden cursor-pointer hover:opacity-70"
                                onClick={() => onAuthorClick(authorData?.fullName as string)}
                                role="button"
                                aria-label={`View ${authorData?.fullName}'s profile`}
                            >
                                <img
                                    src={authorData?.image}
                                    alt={`${authorData?.imageAltText || 'Author'}'s photo`}
                                    className="w-full h-full object-cover"
                                />
                            </div>
                            {/* Author Name */}
                            <button
                                aria-label={`learn more about blog author ${authorData?.fullName}`}
                                onClick={() => onAuthorClick(authorData?.fullName as string)} //this is going to be dynamic, just a placeholder for now
                                className="flex flex-col items-center outline-none border-none bg-transparent h-full mt-4"
                            >
                                <Link to={`${authorUrl}`} aria-label={`Read more about ${authorData?.fullName}`}>
                                    <Typography
                                        variant="pDefault"
                                        color="milan-text-navy"
                                        tag="p"
                                        className="m-0 underline hover:opacity-70 !font-bold"
                                    >
                                        {authorData?.fullName}
                                    </Typography>
                                </Link>
                            </button>
                        </div>
                    </>
                </div>
            </div>
        </div>
    );
}
