import React from 'react';
import { SectionWithImage } from './SectionWithImageContent.builder';
import { SectionWithImageProps } from '../../types/blogs/sectionWithImage';

export function SectionWithImageBuilder({
    headline,
    headingColor,
    richTextContent,
    sectionImage,
    sectionImageAlt,
    isImageOnRight
}: SectionWithImageProps) {
    return (
        <section className="container mx-auto pb-50" id="section-with-image">
            <SectionWithImage
                headline={headline}
                headingColor={headingColor}
                richTextContent={richTextContent}
                sectionImage={sectionImage}
                sectionImageAlt={sectionImageAlt}
                isImageOnRight={isImageOnRight}
            />
        </section>
    );
}