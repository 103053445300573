import { SignUpModal } from '../../../shared';
import React, { useContext, useState } from 'react';
import { GlobalSiteDataContext } from '../../../../context';
import { DynamicHeroContent } from './DynamicHeroContent';

const styles = {
    image: {
        objectFit: 'cover',
        objectPosition: 'right center',
        width: '100%',
        height: '100%',
    },

    backgrounds: {
        section: (color) => ({ backgroundColor: color }),
        content: (color) => ({ backgroundColor: color }),
    },
};

const commonClasses = {
    imageContainer: 'h-100 position-relative',
    contentWrapper: 'd-flex flex-column justify-content-center h-100',
};

export function DynamicHeroBuilder({
    heroDesktopImage,
    heroTabletImage,
    heroMobileImage,
    heroMobileImageAlt,
    typefaceLogo,
    heading,
    headingColor,
    headingColorMobileAndTablet,
    disclaimerColor,
    disclaimerColorMobileAndTablet,
    disclaimerLinkText,
    disclaimerTextPromise,
    buttonLink,
    buttonLabel,
    richTextContent,
    isHeroImgOnRight,
    footerText,
    logoImageAlt,
    desktopImageBackgroundColor,
    desktopBackgroundColor,
    contentBackgroundColor,
    footerTextColor,
    footerTextColorMobileAndTablet,
}) {
    const siteData = useContext(GlobalSiteDataContext);
    const [showSignUpModal, setShowSignUpModal] = useState(false);

    return (
        // Main container with desktop background color
        <section
            className="position-relative overflow-hidden"
            style={styles.backgrounds.section(desktopImageBackgroundColor)}
        >
            {/* Modal Component */}
            {showSignUpModal && (
                <SignUpModal
                    siteData={siteData}
                    showSignUpModal={showSignUpModal}
                    setShowSignUpModal={setShowSignUpModal}
                />
            )}

            {/* Main content container using Bootstrap grid */}
            <div className="container-fluid px-0">
                <div className={`row ${isHeroImgOnRight ? 'flex-row-reverse' : ''}`}>
                    {/* Image Column */}
                    <div className="col-12 col-lg-6 px-0">
                        <div className={commonClasses.imageContainer}>
                            {/* Mobile Image */}
                            <picture>
                                <source media="(min-width: 992px)" srcSet={heroDesktopImage} />
                                <source media="(min-width: 600px)" srcSet={heroTabletImage} />
                                <img
                                    src={heroMobileImage}
                                    alt={heroMobileImageAlt}
                                    style={styles.image}
                                    className="img-fluid"
                                />
                            </picture>
                        </div>
                    </div>

                    {/* Content Column */}
                    <div className="col-12 col-lg-6" style={styles.backgrounds.content(desktopBackgroundColor)}>
                        {/* Desktop Content with right padding */}
                        <div className="d-none d-lg-block h-100">
                            <div className="container h-100">
                                <div className="row h-100 pt-xxl-5">
                                    <div className="col-lg-12 col-xxl-9 col-xxxl-9 h-100 pe-xxl-5 milan-py-lg">
                                        {' '}
                                        {/* Using col-11 to create right padding */}
                                        <div className={`${commonClasses.contentWrapper} py-5`}>
                                            <DynamicHeroContent
                                                {...{
                                                    buttonLabel,
                                                    buttonLink,
                                                    heading,
                                                    headingColor,
                                                    disclaimerLinkText,
                                                    disclaimerTextPromise,
                                                    disclaimerColor,
                                                    logoImageAlt,
                                                    richTextContent,
                                                    setShowSignUpModal,
                                                    typefaceLogo,
                                                    footerText,
                                                    footerTextColor,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Mobile/Tablet Content */}
                        <div
                            className="d-block d-lg-none h-100"
                            style={styles.backgrounds.content(contentBackgroundColor)}
                        >
                            <div className={`${commonClasses.contentWrapper} container milan-py`}>
                                <DynamicHeroContent
                                    {...{
                                        buttonLabel,
                                        buttonLink,
                                        heading,
                                        headingColor: headingColorMobileAndTablet,
                                        disclaimerLinkText,
                                        disclaimerTextPromise,
                                        disclaimerColor: disclaimerColorMobileAndTablet,
                                        logoImageAlt,
                                        richTextContent,
                                        setShowSignUpModal,
                                        typefaceLogo,
                                        footerText,
                                        footerTextColor: footerTextColorMobileAndTablet,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
