 
import { Builder } from '@builder.io/react';
import { BUILDER_BLOG_COMPONENTS_NAMES } from '../BuilderComponentsName';
import { BlogTextSection } from '../components/blogs/BlogTextSection';

Builder.registerComponent(BlogTextSection, {
    name: BUILDER_BLOG_COMPONENTS_NAMES.BlogTextSection,
    friendlyName: 'Section With Text Only',
    inputs: [
        {
            name: 'headline',
            type: 'string',
            required: true,
            defaultValue: 'Maximizing Results And Longevity',
            helperText: 'Enter headline (max 50 characters)',
            regex: {
                pattern: '^.{1,50}$',
                message: 'Headline must not exceed 50 characters',
            },
        },
        {
            name: 'content',
            type: 'richText',
            required: true,
            defaultValue: `<p>Following some key practices can enhance your laser hair removal experience and extend the time you can enjoy your hair-free skin. These include:</p>
            <h3 id="completing" class="p-subhead-tw">Completing the Recommended Treatment Plan</h3>
            <p>Maintaining a routine is important to keep yourself hair-free and silky-smooth. Attend every appointment approximately every six weeks apart. Not doing so could lead to hair regrowth in the treated area.</p>
            <h3 id="maintaining" class="p-subhead-tw">Maintaining a Healthy Lifestyle</h3>
            <p>A healthy life leads to a happy life, and the same applies to hair growth. Managing stress and maintaining a healthy diet with exercise can impact a person's hair growth.</p>
            <h3 id="section-with-image" class="p-subhead-tw">Following Aftercare Instructions</h3>
            <p>This isn't only about exfoliating and moisturizing. Your laser hair removal professional will stress the importance of sun safety, too. Too much sun can prolong your hair-free journey because your baseline skin tone must be maintained in order to be safely treated.</p>`,
            helperText: 'Main content - use h2 tags for section headings and p tags for paragraphs'
        }
    ]
});