// BlogPostHero.tsx
import React from 'react';
import { Typography } from '../../../Typography/Typography';
import { BlogHeroProps } from '../../types/blogs/blogHero';
import { format } from 'date-fns';
import { BlogHeroContent } from './BlogHeroContent';
import { validateBlogUrl, getAuthorUrl } from '../../../builder/utils/blog';
import { removeAllCategoriesFromStringArr } from '../../../../utils/general/builder';
import { navigate } from 'gatsby';

export const handleCategoryButtonClick = (category: string) => {
    const lowerCaseCategory = category.toLowerCase();
    return navigate(`/blog?category=${lowerCaseCategory}`);
};

export function BlogPostHero({ builderState }: BlogHeroProps) {
    const authorData = builderState?.state?.blogs?.data?.author?.value?.data || {};
    const featuredImageAlt = builderState?.state?.blogs?.data?.imageAlt || '';
    const blogCategories = builderState?.state?.blogs?.data?.categories || [];
    const categories = blogCategories && removeAllCategoriesFromStringArr(blogCategories);
    const publishedDate = builderState?.state?.blogs?.data?.publishedDate || '';
    const headline = builderState?.state?.blogs?.data?.headline || 'Blog Post Headline';
    const featuredImage = builderState?.state?.blogs?.data?.image || 'Blog Post Headline';
    const minuteRead = Number(builderState?.state?.blogs?.data?.minuteRead) || 0;

    const fullName = authorData.fullName || builderState?.state?.authors?.data?.fullName || 'Author Name';
    const avatarImage = authorData.image || '';
    const imageAltText =
        authorData.imageAltText || builderState?.state?.authors?.data?.imageAltText || `${fullName}'s photo`;

    const blogUrl = '/blogs';
    const formattedDate = publishedDate && format(new Date(publishedDate), 'MMM dd yyyy');
    const authorUrl = getAuthorUrl(fullName);

    return (
        <>
            <section className="container mx-auto px-4 md:py-50">
                <div className="hidden md:block relative">
                    <div className="flex items-center">
                        <BlogHeroContent
                            publishDate={formattedDate}
                            minuteRead={minuteRead}
                            headline={headline}
                            categories={categories}
                            fullName={fullName}
                            avatarImage={avatarImage}
                            imageAltText={imageAltText}
                            showCategories={true}
                            blogUrl={blogUrl}
                            handleCategoryButtonClick={handleCategoryButtonClick}
                            onAuthorClick={() => {
                                if (typeof window !== 'undefined' && validateBlogUrl(authorUrl)) {
                                    window.location.href = authorUrl;
                                }
                            }}
                        />
                        {/* Feature Image container */}
                        <div className="flex-1 relative -ml-[268px] justify-center items-center md:-ml-[180px]">
                            <img
                                src={featuredImage}
                                alt={featuredImageAlt}
                                className="w-full h-full sm:min-h-[608.88px] sm:max-w-[638px] lg:max-w-none object-cover sm:object-[80%] lg:object-cover rounded-[25.865px]"
                            />
                        </div>
                    </div>
                </div>
            </section>
            {/* New mobile implementation replacing the empty section */}
            <section className="md:hidden relative  min-h-auto overflow-hidden]">
                {/* Blue container with author info and buttons */}
                <div className="bg-milan-blue px-4 pt-[50px]  pb-24 relative z-0 flex flex-col items-start justify-center gap-4">
                    {/* Date and Read Time - mobile layout */}
                    <div className="flex items-center gap-2">
                        <Typography variant="h4Footer" color="milan-text-white" tag="span">
                            {formattedDate}
                        </Typography>
                        <Typography variant="h4Footer" color="milan-text-white" tag="span">
                            •
                        </Typography>
                        <Typography variant="h4Footer" color="milan-text-white" tag="span">
                            {minuteRead} MINUTE READ
                        </Typography>
                    </div>

                    {/* Headline - mobile specific font size */}
                    <Typography
                        variant="h1SmallTitle"
                        color="milan-text-white"
                        tag="h1"
                        className="!pb-0 break-words overflow-wrap break-word" // Mobile specific text size
                    >
                        {headline}
                    </Typography>

                    {/* Action Buttons - stacked for mobile */}
                    {/* Action Buttons - Mobile Layout with Dynamic Sizing */}
                    <div className="flex flex-row justify-start flex-wrap gap-[20.62px] md:w-full">
                        {categories.map((category, index) => (
                            <button
                                key={index}
                                onClick={() => handleCategoryButtonClick(category)}
                                aria-label={`blog category ${category}`}
                                className="bg-milan-secondary-blue text-milan-navy px-[19.33px] py-[6.33px] rounded-[12.89px] font-medium flex items-center justify-center hover:bg-milan-white outline-none border-none font-open-sans text-base"
                            >
                                {category}
                            </button>
                        ))}
                    </div>
                </div>

                {/* Featured Image - mobile specific height */}
                <div className="relative w-full px-3 sm:pt-0 flex justify-center left-1/2 transform -translate-x-1/2 mt-[-60px] z-10">
                    <img
                        src={featuredImage}
                        alt={featuredImageAlt}
                        className="w-full h-auto object-cover rounded-[25.865px]"
                    />
                </div>

                {/* Author Info - mobile specific sizes */}
                <div className="flex items-center px-4 pt-14 justify-start gap-3 mb-[50px]">
                    <div
                        className="flex items-center gap-3 cursor-pointer"
                        onClick={() => {
                            if (typeof window !== 'undefined' && validateBlogUrl(authorUrl)) {
                                window.location.href = authorUrl;
                            }
                        }}
                        role="button"
                        aria-label={`View ${fullName}'s profile`}
                    >
                        <div className="w-14 h-14 rounded-full overflow-hidden">
                            <img
                                src={avatarImage}
                                alt={`${imageAltText || 'Author'}'s photo`}
                                className="w-full h-full object-cover"
                            />
                        </div>
                        <Typography
                            variant="pDefault"
                            color="milan-text-navy"
                            tag="p"
                            className="underline hover:opacity-70"
                        >
                            {fullName}
                        </Typography>
                    </div>
                </div>
            </section>
        </>
    );
}
