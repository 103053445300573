// src/components/builder/components/blogs/ConsultationSection.builder.ts
import { Builder } from '@builder.io/react';
import { BUILDER_BLOG_COMPONENTS_NAMES } from '../BuilderComponentsName';
import { OurLatestBlogs } from '../components/blogs/OurLatestBlog/OurLatestBlogs';

Builder.registerComponent(OurLatestBlogs, {
    name: BUILDER_BLOG_COMPONENTS_NAMES.OurLatestBlogs,
    friendlyName: 'Our Latest Blogs',
    image: 'https://cdn.builder.io/api/v1/image/assets%2F94ef41a0564f46a39dbe92e6ffeda03f%2Ff37abb7d3ff24e4c934dfeafd6c3af67',
    inputs: [
        {
            name: 'headline',
            friendlyName: 'Headline',
            type: 'string',
            required: false,
            defaultValue: `Our Latest Blogs`,
            helperText: 'Enter headline (max 50 characters)',
            regex: {
                pattern: '^.{1,50}$',
                message: 'Headline must not exceed 50 characters',
            },
          
        },
      
    ],
});