import React, { JSX } from 'react';
import { PopularBlogsContent } from './PopularBlogsContent';
import { BuilderComponentState, PopularBlogsBuilderProps } from '../../types';
import { Typography } from '../../../Typography/Typography';
import './BlogsSectionStyles.css';
import { getBuilderComponentState } from '../../utils/blog';
import { useLocation } from '@reach/router';

const pathsWithException = ['/blog'];

export function PopularBlogsBuilder({
    heading = 'Popular Blogs',
    headingColor = 'milan-text-white',
    builderState,
}: PopularBlogsBuilderProps): JSX.Element {
    const location = useLocation();

    const isExtendedBlogsKeys = pathsWithException.some((path) => path === location?.pathname);

    //this is a builder data binding key for the blogs,
    // if there is no other component using the blog data,
    // then use the default keys or else use the extended keys
    const blogsKeys = isExtendedBlogsKeys ? ['blogs4', 'blogs5', 'blogs6'] : ['blogs', 'blogs2', 'blogs3'];

    const blogsState = builderState?.state as BuilderComponentState['state'];

    const blogs = getBuilderComponentState(blogsState, blogsKeys);

    return (
        <section className="container-fluid px-0 py-milan-50 bg-milan-lt-grey">
            <div className="container pr-0">
                <Typography variant="h2SubheadLg" color={headingColor} className="break-words" tag="h2">
                    {heading}
                </Typography>
                <PopularBlogsContent blogs={blogs} />
            </div>
        </section>
    );
}
