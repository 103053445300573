import { AuthorData, AuthorModelDataType } from 'components/builder/types';
import { BlogDataType } from 'components/builder/types/blogs/blogHero';
import React from 'react';
import { Card } from './Card';
import { removeAllCategoriesFromBlogs } from '../../../../../utils/general/builder';

type CardListProps = {
    blogs: BlogDataType[];
    authors: AuthorModelDataType[];
    activeFilter: string;
    handleFilterChange: (filter: string) => void;
    handleAuthorClick: (authorName: string) => void;
};

export function CardList({
    blogs,
    authors,
    // isBlogFetching,
    activeFilter,
    handleAuthorClick,
    handleFilterChange,
}: CardListProps) {
    const updatedBlogsToRemoveAllCategories = removeAllCategoriesFromBlogs(blogs);
    if (!updatedBlogsToRemoveAllCategories) {
        <></>;
    } else {
        return (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {updatedBlogsToRemoveAllCategories?.map((blog, index) => {
                    const blogData = blog?.data;
                    const authorData = authors && authors?.find((datum) => datum?.id === blogData?.author?.id)?.data;
                    return (
                        <Card
                            key={index}
                            blogData={blogData}
                            onFilterChange={handleFilterChange}
                            activeFilter={activeFilter}
                            authorData={authorData! as AuthorData}
                            onAuthorClick={handleAuthorClick}
                        />
                    );
                })}
            </div>
        );
    }
}
