 
import {  BlogHeroProps } from '../types/blogs/blogHero';
import { getBuilderModelData } from '../../../api/builderRestApiClient';
import { BREAKPOINTS } from '../../../utils/constants';
import { BuilderComponentState } from '../types';

export interface BlogDataResult {
    authorData: any;
    featuredImageAlt: string;
    categories: string[];
    publishedDate: string;
    headline: string;
    featuredImage: string;
    minuteRead: number;
    fullName: string;
    avatarImage: string;
    imageAltText: string;
    blogUrl: string;
    authorUrl: string;
}

export const getBlogData = (
    blogKey: 'blogs' | 'blogs2' | 'blogs3',
    builderState: BlogHeroProps['builderState']
): BlogDataResult => {
    const blogData = builderState?.state?.[blogKey]?.data;
    const authorRef = blogData?.author;
    const authorData = authorRef?.value?.data || {};
    const authorName = authorData?.fullName || '';
    const url = `blog${blogData?.pageUrl}` //This is going to be in the format 'blog/is-laser-hair-removal-permanent'
    const authorUrl = getAuthorUrl(authorName);

    return {
        authorData,
        featuredImageAlt: blogData?.imageAlt || '',
        categories: blogData?.categories || [],
        publishedDate: blogData?.publishedDate || '',
        headline: blogData?.headline || 'Blog Post Headline',
        featuredImage: blogData?.image || '',
        minuteRead: Number(blogData?.minuteRead) || 0,
        fullName: authorData.fullName || builderState?.state?.authors?.data?.fullName || 'Author Name',
        avatarImage: authorData.image || '',
        imageAltText: authorData.imageAltText ||
            builderState?.state?.authors?.data?.imageAltText ||
            `${authorData.fullName || 'Author'}'s photo`,
        blogUrl: url,
        authorUrl
    };
};
/**
 * Converts a blog headline to a URL-friendly slug
 */
export const convertHeadlineToSlug = (headline: string): string => {
    if (!headline) return '';
    
    return headline
        .toLowerCase()
        .replace(/[^a-zA-Z0-9\s-]/g, '') 
        .replace(/\s+/g, '-')            
        .replace(/-+/g, '-')             
        .replace(/^-+|-+$/g, '');        
};

export const getBlogUrl = (headline: string, builderPageUrl?: string): string => {
    // If Builder.io page URL is available and valid, use it directly
    if (builderPageUrl && builderPageUrl.trim()) {
        const cleanUrl = builderPageUrl.trim();
        // Return the Builder URL as-is if it starts with a slash
        if (cleanUrl.startsWith('/')) {
            return cleanUrl;
        }
        return `/${cleanUrl}`;
    }
    
    // Only add '/blogs/' prefix when generating from headline
    const slug = convertHeadlineToSlug(headline);
    return `/blog/${slug}`;
};

export const getAuthorUrl = (authorName: string, builderPageUrl?: string): string => {
    // If Builder.io page URL is available and valid, use it directly
    if (builderPageUrl && builderPageUrl.trim()) {
        const cleanUrl = builderPageUrl.trim();
        // Return the Builder URL as-is if it starts with a slash
        if (cleanUrl.startsWith('/')) {
            return cleanUrl;
        }
        return `/${cleanUrl}`;
    }
    
    // Only add '/authors/' prefix when generating from name
    const slug = convertHeadlineToSlug(authorName);
    return `/blog/author/${slug}`;
};

// Optional: Add validation function
export const validateBlogUrl = (url: string): boolean => {
    // Ensure URL starts with / and contains only valid characters
    return url.startsWith('/') && 
           /^\/[a-zA-Z0-9\-\/]+$/.test(url) &&
           !url.includes('//');
};

export const getResults = async <T>(limit: number, skip: number, modelName: string): Promise<T[]> => {
    const data = await getBuilderModelData(modelName, {
        limit,
        offset: skip,
        cache: true,
        cacheSeconds: 0, // Cache for 1 hour
    });
    // Return a slice of the data based on the limit and offset
    return data?.results;
};

export const getTotalItems = async <T>(modelName: string) => {
    // Since, builder doesn't provide total count, we'll fetch pages until we get less than the limit. This is from official builder.io documentation(https://www.builder.io/c/docs/content-api)
    // Limit to 100 results per page
    const limit = 100;
    let offset = 0;
    const pages = await getResults<T>(limit, offset, modelName);
    // Fetch more pages until we get less than the limit, meaning we've reached the last page
    while (pages?.length === limit + offset) {
        offset += limit;
        pages?.push(...(await getResults<T>(limit, offset, modelName)));
    }
    return pages;
};

// Helper function for responsive design
export const getItemsPerPageByBreakpoint = (minWidth: number): number => {
    if (minWidth <= BREAKPOINTS.sm) return 3;
    if (minWidth <= BREAKPOINTS.md) return 4;
    return 6;
}


export const getBuilderComponentState = (blogsState: BuilderComponentState['state'], blogsKeys: string[]) => { 
    if (!blogsState) {
        return [];
    }
    
    // Map through blogsKeys to preserve their order
    const blogs = blogsKeys
        .map((key) => {
            // Check if this key exists in blogsState
            if (key in blogsState) {
                return (blogsState as any)[key]?.data;
            } else {
                return null;
            }
        })
        .filter((blog) => blog !== null); // Remove null values from the array
    
    return blogs;
}