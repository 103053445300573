import React from 'react';
import { SectionWithImageProps } from '../../types/blogs/sectionWithImage';
import { Typography } from '../../../Typography/Typography';

export function SectionWithImage({
    headline,
    headingColor,
    richTextContent = '',
    sectionImage,
    sectionImageAlt,
    isImageOnRight,
}: SectionWithImageProps) {
    return (
        <div className="flex flex-col lg:flex-row gap-y-12 lg:gap-x-6">
            <div className={`w-full ${!isImageOnRight ? 'lg:order-2' : ''}`}>
                <Typography variant="h2SubheadLg" tag="h2" color={headingColor} className="break-words capitalize mb-3">
                    {headline}
                </Typography>
                <div className="rich-text-content">
                    <Typography variant="pDefault" tag="div" color="milan-text-navy">
                        <div dangerouslySetInnerHTML={{ __html: richTextContent }} />
                    </Typography>
                </div>
            </div>
            <div className={`w-full ${!isImageOnRight ? 'lg:order-1' : ''}`}>
                <div className="min-h-[181px] sm:min-w-full sm:h-auto md:w-[720px] md:h-auto lg:w-full lg:h-auto xl:w-[636px]">
                    <img
                        src={sectionImage}
                        alt={sectionImageAlt}
                        className="w-full md:h-auto  rounded-[10px] object-cover"
                    />
                </div>
            </div>
        </div>
    );
}
