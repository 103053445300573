import React from 'react';
import { Typography } from '../../../Typography/Typography';
import { ConsultationSectionProps } from '../../types/blogs/consultation';

export function ConsultationSection({
    headline,
    richTextContent,
    consultationImage,
    consultationImageAlt,
    textColor = 'milan-text-white',
    backgroundColor = 'var(--milan-blue)',
}: ConsultationSectionProps): JSX.Element {
    return (
        <section className="w-full py-50" style={{ backgroundColor }} id="consultation-section">
            <div className="container mx-auto sm:px-4">
                <div className="flex flex-col lg:flex-row items-stretch gap-6">
                    <div className="w-full order-2 lg:order-1 lg:flex lg:flex-col lg:justify-end xl:justify-start lg:w-1/2">
                        <div className="w-full">
                            <Typography variant="h2SubheadLg" color={textColor} tag="h2" className="mb-6">
                                {headline}
                            </Typography>
                            <div className="w-full overflow-hidden">
                                <Typography
                                    variant="pDefault"
                                    color="milan-text-white"
                                    tag="p"
                                    className="w-full break-words whitespace-normal overflow-wrap-anywhere"
                                >
                                    <div dangerouslySetInnerHTML={{ __html: richTextContent }} />
                                </Typography>
                            </div>
                        </div>
                    </div>

                    {/* Image Column */}
                    <div className="w-full order-1 lg:order-2 flex justify-center lg:w-1/2 xl:w-2/5">
                        <div className="w-full h-full lg:max-w-[512px] flex-grow">
                            <img
                                src={consultationImage}
                                alt={consultationImageAlt}
                                className="w-full h-[214] rounded-[10px] object-cover
                                    sm:min-h-[214.34px] sm:min-w-[361px]
                                    md:h-[427.5px]
                                    lg:h-[312.31px]"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
