import React from 'react';
import { Typography } from '../../../Typography/Typography';
import { TableOfContents } from './TableOfContents';

interface TOCEntry {
    title: string;
    anchor: string;
}

interface BlogLayoutProps {
    headline: string;
    introduction: string;
    content: string;
    tableOfContents?: TOCEntry[];
}

export function BlogLayout({ headline, introduction, content, tableOfContents = [] }: BlogLayoutProps) {
    return (
        <div className="lg:block">
            <div className="container mx-auto mb-9" id="blog-layout">
                <div className="flex flex-col lg:flex-row gap-16">
                    {/* Main Content */}
                    <div className="flex-1">
                        <Typography
                            variant="pDefault"
                            color="milan-text-navy"
                            tag="h3"
                            className="mb-8 break-words overflow-hidden"
                        >
                            <div dangerouslySetInnerHTML={{ __html: introduction }} />
                        </Typography>

                        <Typography
                            variant="h2SubheadLg"
                            color="milan-text-primary"
                            tag="h2"
                            className="mb-6 !capitalize"
                        >
                            {headline}
                        </Typography>

                        {/* Rich text content */}
                        <div
                            className={`
                                [&>p]:font-open-sans [&>p]:font-regular [&>p]:tracking-paragraph
                                [&>p]:text-paragraph-mobile [&>p]:leading-paragraph-mobile
                                md:[&>p]:text-paragraph-desktop md:[&>p]:leading-paragraph-desktop
                                [&>p]:mb-4 last:[&>p]:mb-0
                                [&>h2]:font-open-sans [&>h2]:font-regular [&>h2]:capitalize
                                [&>h2]:text-paragraph-subhead [&>h2]:leading-subheader-paragraph
                                [&>h2]:mb-4 [&>h2]:mt-8 first:[&>h2]:mt-0
                            `}
                            dangerouslySetInnerHTML={{ __html: content }}
                        />
                    </div>

                    {/* Table of Contents */}
                    {tableOfContents.length > 0 && (
                        <div className=" hidden lg:block lg:w-[258px] shrink-0">
                            <TableOfContents entries={tableOfContents} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
