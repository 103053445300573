import React from 'react';
import { AboutMilanContent } from './AboutMilanContent.builder';
import { AboutMilanSectionProps } from '../../types/blogs/blogs';

export function AboutMilanSectionBuilder({
    heading,
    headingColor,
    richTextContent,
    sectionImage,
    sectionImageAlt,
    isImageOnRight
}: AboutMilanSectionProps) {
    return (
        <section className="container mx-auto pb-50 px-4">
            <AboutMilanContent
                heading={heading}
                headingColor={headingColor}
                richTextContent={richTextContent}
                sectionImage={sectionImage}
                sectionImageAlt={sectionImageAlt}
                isImageOnRight={isImageOnRight}
            />
        </section>
    );
}