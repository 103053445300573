import React from 'react';
import { ChevronDown } from 'lucide-react';

type SortOption = 'newest' | 'oldest';

interface SortSelectorProps {
    selectedSort: SortOption;
    onSortChange: (sort: SortOption) => void;
}

const SortSelector: React.FC<SortSelectorProps> = ({ selectedSort, onSortChange }) => {
    const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newSort = event.target.value as SortOption;
        onSortChange(newSort);
    };

    return (
        <div className="relative group flex items-center gap-2">
            <select
                onChange={handleSortChange}
                value={selectedSort}
                className="appearance-none px-[30px] py-[8.5px] border border-milan-grey rounded-[5px] focus:outline-none text-milan-navy hover:text-milan-turquoise cursor-pointer decorated"
            >
                <option value="newest">Newest</option>
                <option value="oldest">Oldest</option>
            </select>
            <ChevronDown
                className="absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none text-milan-navy group-hover:text-milan-turquoise"
                size={16}
            />
        </div>
    );
};

export default SortSelector;
