import { Builder } from '@builder.io/react';
import { BUILDER_DIVIDER_NAMES } from '../BuilderComponentsName';
import { AboutAuthorBuilder } from '../components/blogs/AboutAuthor';

Builder.registerComponent(AboutAuthorBuilder, {
    name: BUILDER_DIVIDER_NAMES.AboutAuthor,
    friendlyName: 'About Author Section',
    inputs: [
        {
            name: 'heading',
            type: 'text',
            defaultValue: 'About Dr. Nate H.',
            required: true,
            helperText: 'Enter heading (max 50 characters)',
            regex: {
                pattern: '^.{1,50}$',
                message: 'Heading must not exceed 50 characters'
            }
        },
        {
            name: 'headingColor',
            friendlyName: 'Heading Color',
            type: 'string',
            enum: ['milan-text-primary', 'milan-text-white', 'milan-text-navy', 'milan-text-black'],
            defaultValue: 'milan-text-primary',
            required: true,
            helperText: 'Select the color for the heading text (primary=blue)',
        },
        {
            name: 'authorContent',
            friendlyName: 'Author Bio Content',
            type: 'richText',
            required: true,
            defaultValue: `<p class="milan-text-navy">Dr. Nate Haecker is board-certified in Family Medicine and has helped Milan Laser become the nation's largest provider of laser hair removal with more than 380 clinics nationwide.</p>
            <p class="milan-text-navy">Dr. Haecker graduated with a Bachelor of Arts and Doctor of Medicine from Creighton University. He completed a residency in Family Medicine through the University of Nebraska Medical Center and a Master of Business Administration through Pittsburg State University.</p>
            <p class="milan-text-navy">Dr. Haecker is married and has three children. He enjoys attending his kids’ various activities, remodeling homes, and playing the saxophone. He loves supporting his alma mater, the Creighton Bluejays. He and his wife also like vacationing near the beach, especially Puerto Vallarta.</p>`,
        },
     
    ],
});