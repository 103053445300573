import { Builder } from '@builder.io/react';
import {
    BUILDER_BLOG_COMPONENTS_NAMES,
    BUILDER_DIVIDER_NAMES,
} from './src/components/builder/BuilderComponentsName'; /**
 * Import all custom components so you can use in the builder.io editor
 * https://www.builder.io/c/docs/custom-react-components
 */

// Design Tokens
import { milanDesignTokens } from './src/components/builder/utils/builderDesignTokens';

// Dividers
import './src/components/builder/dividers/WaxingCostPerYear.builder';
import './src/components/builder/dividers/AffordablePlansDivider.builder';
import './src/components/builder/dividers/RatingsDivider.builder';
import './src/components/builder/dividers/LipsOrUnderArmsDivider.builder';
import './src/components/builder/dividers/RatingsAndLipsOrUnderarmDivider.divider';
import './src/components/builder/dividers/SectionDivider.builder';
import './src/components/builder/dividers/LocationsDivider.builder';
import './src/components/builder/dividers/PullQuote.builder';

// Sections
import './src/components/builder/sections/PopularBlogs.builder';
import './src/components/builder/sections/UnlimitedPackage2.builder';
import './src/components/builder/sections/UnlimitedPackage.builder';
import './src/components/builder/sections/WhyMilanLHRIdeal.builder';
import './src/components/builder/sections/LHRBenefits.builder';
import './src/components/builder/sections/HowLHRWorks.builder';
import './src/components/builder/sections/CreditApproval.builder';
import './src/components/builder/sections/MilanCost.builder';
import './src/components/builder/sections/AboutMilanSection.builder';
import './src/components/builder/sections/AboutAuthor.builder';

// Interactive Sections
import './src/components/builder/interactive-sections/ShavingWaxingCalc.builder';

// Videos
import './src/components/builder/videos/ReasonsLHRVimeo.builder';
import './src/components/builder/videos/WhatOurCustomersSay';

// Hero
import './src/components/builder/hero/HeroWithImageAndLinkOnly.builder';
import './src/components/builder/hero/SpecialsHero.builder';
import './src/components/builder/hero/SpecialsHero.builder.V2';
import './src/components/builder/hero/AuthorAvatarHero.builder';

// Blog Sections
import './src/components/builder/sections/BlogPostHero.builder';
import './src/components/builder/sections/ConsulationSection.builder';
import './src/components/builder/sections/SectionWithImage.builder';
import './src/components/builder/sections/BlogLayout.builder';
import './src/components/builder/sections/BlogTextSection.builder';
import './src/components/builder/sections/BlogsFAQs.builder';
import './src/components/builder/sections/BlogHeroCarousel.builder';
import './src/components/builder/blogs/OurLatestBlog.builder';

// Register a custom insert menu to add your custom components here
Builder.register('editor.settings', milanDesignTokens);

// Dividers
// Register a custom insert menu to add your custom components here
// Dividers
Builder.register('insertMenu', {
    name: 'Static Dividers',
    items: [
        { name: BUILDER_DIVIDER_NAMES.WaxingCostPerYear },
        { name: BUILDER_DIVIDER_NAMES.AffordablePlansDivider },
        { name: BUILDER_DIVIDER_NAMES.RatingsDivider },
        { name: BUILDER_DIVIDER_NAMES.LipsOrUnderArmsDivider },
        { name: BUILDER_DIVIDER_NAMES.RatingsAndLipsOrUnderarmDivider },
        { name: BUILDER_DIVIDER_NAMES.SectionDivider },
        { name: BUILDER_DIVIDER_NAMES.LocationsBanner },
    ],
});

// Sections
Builder.register('insertMenu', {
    name: 'Static Sections',
    items: [
        { name: BUILDER_DIVIDER_NAMES.UnlimitedPackage },
        { name: BUILDER_DIVIDER_NAMES.UnlimitedPackage2 },
        { name: BUILDER_DIVIDER_NAMES.MilanCost },
        { item: BUILDER_DIVIDER_NAMES.WhyMilanLHRIsIdeal, name: 'Why Milan LHR is Ideal' },
        { item: BUILDER_DIVIDER_NAMES.HowLHRWorks, name: 'How LHR Works' },
        { name: BUILDER_DIVIDER_NAMES.CreditApproval },
        { item: BUILDER_DIVIDER_NAMES.LHRBenefits, name: 'LHR Benefits' },
    ],
});

// Interactive Sections
Builder.register('insertMenu', {
    name: 'Interactive Sections',
    items: [{ name: BUILDER_DIVIDER_NAMES.ShavingAndWaxingCalc }],
});

// Videos
Builder.register('insertMenu', {
    name: 'Videos Sections',
    items: [
        { item: BUILDER_DIVIDER_NAMES.ReasonsLHRVimeo, name: 'Reasons LHR Vimeo' },
        { name: BUILDER_DIVIDER_NAMES.WhatCustomersSay },
    ],
});

// Hero
Builder.register('insertMenu', {
    name: 'Hero Sections',
    items: [
        { name: BUILDER_DIVIDER_NAMES.DynamicHero },
        { name: BUILDER_DIVIDER_NAMES.HeroWithImageAndLinkOnly },
        { name: BUILDER_DIVIDER_NAMES.DynamicHeroV2 },
    ],
});

// Blog Sections
Builder.register('insertMenu', {
    name: 'Blog Sections',
    items: [
        { name: BUILDER_DIVIDER_NAMES.AboutMilanSection },
        { name: BUILDER_DIVIDER_NAMES.AboutAuthor },
        { name: BUILDER_DIVIDER_NAMES.PullQuote },
        { name: BUILDER_DIVIDER_NAMES.PopularBlogs },
        { name: BUILDER_DIVIDER_NAMES.AuthorAvatarHero },
        { name: BUILDER_DIVIDER_NAMES.AuthorHero },
        { name: BUILDER_DIVIDER_NAMES.BlogPostHero },
        { name: BUILDER_DIVIDER_NAMES.ConsultationSection },
        { name: BUILDER_DIVIDER_NAMES.SectionWithImage },
        { name: BUILDER_BLOG_COMPONENTS_NAMES.BlogLayout },
        { name: BUILDER_DIVIDER_NAMES.BlogTextSection },
        { name: BUILDER_BLOG_COMPONENTS_NAMES.BlogFAQs },
        { name: BUILDER_BLOG_COMPONENTS_NAMES.BlogHeroCarousel },
        { name: BUILDER_BLOG_COMPONENTS_NAMES.OurLatestBlogs },
    ],
});
