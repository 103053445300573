import { Builder } from '@builder.io/react';
import { BUILDER_BLOG_COMPONENTS_NAMES } from '../BuilderComponentsName';
import { BlogFAQs } from '../components/blogs/BlogFAQs';

Builder.registerComponent(BlogFAQs, {
    name: BUILDER_BLOG_COMPONENTS_NAMES.BlogFAQs,
    friendlyName: 'FAQs',
    image: 'https://cdn.builder.io/api/v1/image/assets%2F10266dac478d4c768f6b6fbe586ae30e%2F6b11e9a63e7d4c54ba8518e1aafb1e77',
    inputs: [
        {
            name: 'faqs',
            friendlyName: 'FAQs',
            type: 'list',
            required: true,
            helperText: 'Add FAQ items',
            defaultValue: [{
                question: 'Is Laser Hair Removal Safe?',
                answer: "Laser hair removal is generally considered safe when performed by a trained professional using appropriate equipment and techniques. However, there are some potential risks and side effects to keep in mind:<br/><strong>Pain:</strong> Laser hair removal can be uncomfortable or painful for some people. The level of discomfort varies from person to person and depends on the area being treated and the patient's pain tolerance<br/><strong>Skin irritation:</strong> Some common skin reactions include redness, swelling, and itching immediately after treatment. These side effects usually subside within a few hours to a day.<br/><strong>Pigment changes: </strong>People with darker skin tones are at higher risk of developing temporary or permanent skin discoloration due to the laser targeting pigment-producing cells (melanocytes).<br/><strong>Scarring:</strong> In rare cases, laser hair removal may cause scarring if the laser settings are too high or if the skin is damaged before treatment.<br/><strong>Eye injury:</strong> It is crucial to protect the eyes during laser hair removal, as the laser can cause eye injury. The practitioner should provide appropriate eye protection for the patient.<br/><strong>Infection:</strong> Although rare, there is a small risk of infection if the skin is not properly cared for after treatment or if the equipment is not sterile.<br/>To minimize risks, it's essential to choose a reputable and experienced provider who uses FDA-approved lasers and follows proper safety protocols. It's also important to follow pre- and post-treatment care instructions, avoid sun exposure before and after treatments, and keep in touch with your practitioner for any concerns or complications.",
            }],
            subFields: [
                {
                    name: 'question',
                    type: 'longText',
                    required: true,
                    helperText: 'Enter the faq question',
                },
                {
                    name: 'answer',
                    type: 'richText',
                    required: true,
                    helperText: 'Enter the answer',
                },
            ],
        },

        {
            name: 'heading',
            type: 'text',
            defaultValue: 'Frequently Asked Questions',
            required: true,
            friendlyName: 'Heading',
        }
     
    ],
});