import React from 'react';
import { useMilanStores, useMilanOpenStores, useGlobalData } from '../hooks';
import { getStore, getMilanGeoCodes } from '../utils';
import { useBuilderPromotionsBannerData } from '../hooks/useBuilderPromotionsBannerData';

export const GlobalSiteDataContext = React.createContext();

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== 'undefined';

export const GlobalContextProvider = ({ children }) => {
    const { milanStores, selectedStore, setSelectedStore } = useMilanStores(); // returns all milan stores from firebase
    const milanOpenStores = useMilanOpenStores(milanStores); // all Milan open stores
    const globalData = useGlobalData();
    const milanGeoCodes = getMilanGeoCodes(milanStores); // returns every Milan Location's geoCode

    const { promotionalBannersTextData, isLoading: isBuilderDataLoading } = useBuilderPromotionsBannerData();

    const updateSelectedStore = (salesforceValue) => {
        let store = getStore(salesforceValue, milanStores);
        setSelectedStore(store);
        if (isBrowser) window.localStorage.setItem('selectedStore', salesforceValue);
    };

    const siteData = {
        milanStores,
        milanOpenStores,
        globalData,
        selectedStore,
        updateSelectedStore,
        milanGeoCodes,
        promotionalBannersTextData,
        isBuilderDataLoading,
    };

    return <GlobalSiteDataContext.Provider value={siteData}>{children}</GlobalSiteDataContext.Provider>;
};

// ------------------------------------ Deprecated -------------------------------------------//
// ********* You can query siteDataJson instead of allSitePlugin ****** //
// const localSiteData = useStaticQuery(graphql`
// query LocalSiteData {
//   siteDataJson {
//     stores {
//       marketingCity
//       pathname
//       clinicName
//       address
//       addressCity
//       stateAbbrev
//       zipCode
//       phone
//       salesforceValue
//       place_id
//       open
//       platforms {
//         facebook
//         google
//         reviews {
//           list_token
//           slide_token
//         }
//         yelp
//       }
//       description {
//         line_one
//         line_two
//       }
//       with_pro_images
//       hero {
//         reversed
//         position
//       }
//       maps {
//         google
//         apple
//       }
//     }
//     state
//     pathname
//     metroPath
//     origin
//     name
//     stateAbbrev
//     grandOpening
//     google_analytics_id
//     bing_id
//   }
// }
// `).siteDataJson

// ---------------------------------------------------------------------------------------------//
// **** If you want to get the crrentMetro from Firebase ******* //

// const [currentMetro, setCurrentMetro] = useState(null)

// const getCurrentMetro = (milanStores, siteMetaData) => {
//   const currentState = milanStores.find(state => state.name === siteMetaData.state)
//   const currentMetro = currentState.metros.find(metro => metro.name === siteMetaData.metro)
//   return currentMetro
// }

// useEffect(() => {
//   if(milanStores.length) {
//     setCurrentMetro(getCurrentMetro(milanStores, data.site.siteMetadata))
//   }
// }, [milanStores])
