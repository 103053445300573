import React from 'react';
import { AboutAuthorProps } from '../../types';
import { Typography } from '../../../Typography/Typography';

export function AboutAuthorBuilder({ heading, headingColor, authorContent }: AboutAuthorProps) {
    return (
        <div className="container mx-auto px-4 pt-12 md:py-0">
            <div className="w-full mx-auto">
                <Typography variant="h2SubheadLg" color={headingColor} className="mb-6 break-words" tag='h2'>
                    {heading}
                </Typography>

                <Typography variant="pDefault" className="break-words whitespace-normal" color="milan-text-navy" tag='p'>
                    <div dangerouslySetInnerHTML={{ __html: authorContent }} />
                </Typography>
            </div>
        </div>
    );
}
