import React from 'react';
import { Link } from 'gatsby';

export function Terms() {
    return (
        <div className="hero milan-bg-off-white">
            <div className="container">
                <h1>
                    <span className="d-block milan-text-primary">Messaging Terms and Conditions</span>
                    <span className="smalltitle milan-text-tur">
                        Milan Laser Corporate LLC doing business as Milan Laser Hair Removal
                    </span>
                </h1>
                <p className="milan-fp fw">Effective: August 27, 2024</p>
                <p class="p-subhead mb-3 milan-text-primary">
                    <strong>1. Introduction</strong>
                </p>
                <p>
                    These Messaging Terms and Conditions ("Terms") govern your use of the Milan Laser Hair Removal
                    mobile messaging service (the "Mobile Service"). The Mobile Service is operated by Milan Laser
                    Corporate LLC doing business as Milan Laser Hair Removal (“Milan Laser”, “we”, “our”, or “us”).
                </p>
                <p>
                    By registering for an account and using the Mobile Services, you agree to be bound by these Terms
                    and our Privacy Policy (located at{' '}
                    <Link to="/privacy" className="fw-normal">
                        Privacy Policy
                    </Link>
                    ). By opting in to receive messages from us and/or your use of the Mobile Service constitutes your
                    agreement to the Terms.
                </p>
                <p class="p-subhead mb-3 milan-text-primary">
                    <strong>2. Service Description</strong>
                </p>
                <p>
                    You consent to receive recurring automated promotional and personalized marketing text messages from
                    Milan Laser’s electronic messaging services, including, but not limited to, SMS, MMS, instant
                    messaging, chatbots, and other messaging functionalities (the "Mobile Service"). You agree to
                    receive marketing text messages from and on behalf of Milan Laser through your wireless provider to
                    the mobile number you provided, even if your mobile number is registered on any state or federal Do
                    Not Call list. Your consent Includes text messages that may be sent using an automatic telephone
                    dialing system or other technology.
                </p>
                <p>
                    The Mobile Service allows us to send you text messages (SMS and MMS) to your mobile phone number.
                    These service-related messages may include:
                </p>
                <ul className="list-style-disc">
                    <li>
                        Updates, alerts, and information (e.g., order updates, account alerts, order confirmations,
                        shipping updates, etc.)
                    </li>
                    <li>
                        Promotional offers and discount messages may include promotions, specials, and other marketing
                        offers (e.g., cart reminders)
                    </li>
                    <li>Appointment reminders and confirmations</li>
                    <li>Customer service notifications</li>
                    <li>Account information</li>
                </ul>
                <p>
                    You understand that you do not have to sign up for the Mobile Service in order to make any
                    purchases, and your consent is not a condition of any purchase with Milan Laser. Your participation
                    in the Mobile Service is completely voluntary.
                </p>
                <p class="p-subhead mb-3 milan-text-primary">
                    <strong>3. Message Frequency and Opt-Out</strong>
                </p>
                <p>
                    Message frequency will vary. Milan Laser reserves the right to alter the frequency of messages sent
                    at any time, to increase or decrease the total number of sent messages.
                </p>
                <p>
                    You may opt out of the Mobile Service at any time. Text STOP, END, CANCEL, UNSUBSCRIBE, or QUIT to
                    our shortcode to cancel, or click the unsubscribe link (where available) in any text message to
                    cancel. You will receive a one-time opt-out confirmation text message. You acknowledge that our text
                    message platform may not recognize and respond to unsubscribe requests that do not include the STOP,
                    END, CANCEL, UNSUBSCRIBE, or QUIT keyword commands and agree that Milan Laser and its service
                    providers will have no liability for failing to honor such requests. No further messages will be
                    sent to your mobile device, unless initiated by you.
                </p>
                <p>
                    If you have subscribed to other Milan Laser mobile message programs and wish to cancel, except where
                    applicable law requires otherwise, you will need to opt out separately from those programs by
                    following the instructions provided in their respective mobile terms.
                </p>
                <p>
                    You can also opt out by contacting us at{' '}
                    <a href="tel:18336672967" className="fw-normal">
                        1-833-667-2967
                    </a>{' '}
                    or{' '}
                    <a href="mailto: privacy@milanlaser.com" className="fw-normal">
                        privacy@milanlaser.com
                    </a>
                    .
                </p>
                <p class="p-subhead mb-3 milan-text-primary">
                    <strong>4. Message Fees</strong>
                </p>
                <p>
                    We do not charge for the Mobile Service, but you are responsible for all third-party access charges
                    associated with text messaging imposed by your wireless provider. Message and data rates may apply.
                    Therefore, you may incur message and data rates from your wireless carrier for receiving messages.
                    Check your mobile plan and contact your wireless provider for details. You are solely responsible
                    for all charges related to SMS/text messages, including charges from your wireless provider. We are
                    not responsible for any charges imposed by your carrier.
                </p>
                <p class="p-subhead mb-3 milan-text-primary">
                    <strong>5. Your Responsibilities</strong>
                </p>
                <p>
                    By using the Mobile Service, you represent that you are at least sixteen (16) years old and have the
                    legal capacity to enter into this agreement.
                </p>
                <p>
                    You are also responsible for ensuring that the mobile phone number you provide is accurate and
                    up-to-date. You agree to provide us with a valid mobile number. If you get a new mobile number, you
                    will need to sign up for the Mobile Service with your new number.
                </p>
                <p>
                    You agree to comply with all applicable laws when using the Mobile Services. You agree to use the
                    Mobile Service only for lawful purposes and in accordance with these Terms. You shall not upload,
                    transmit, or share any content that is illegal, harmful, offensive, or otherwise objectionable.
                </p>
                <p>
                    You are solely responsible for maintaining the confidentiality of your account credentials and for
                    any activities that occur in your account. You must immediately notify Milan Laser of any
                    unauthorized use of your account.
                </p>
                <p class="p-subhead mb-3 milan-text-primary">
                    <strong>6. Our Responsibilities</strong>
                </p>
                <p>
                    We will use commercially reasonable efforts to deliver messages to your mobile phone number.
                    However, we cannot guarantee the timely delivery of all messages. We reserve the right to modify or
                    discontinue the Mobile Service at any time without notice.
                </p>
                <p class="p-subhead mb-3 milan-text-primary">
                    <strong>7. Privacy</strong>
                </p>
                <p>
                    We respect your privacy and will use your information only as described in our{' '}
                    <Link to="/privacy" className="fw-normal text-decoration-underline">
                        Privacy Policy
                    </Link>
                    . To see how we collect and use your personal information, please see our{' '}
                    <Link to="/privacy" className="fw-normal text-decoration-underline">
                        Privacy Policy
                    </Link>
                    .
                </p>
                <p class="p-subhead mb-3 milan-text-primary">
                    <strong>8. Disclaimer</strong>
                </p>
                <p>
                    The Mobile Service is provided "as is" and without warranties of any kind, express or implied. We
                    disclaim all warranties, including, but not limited to, warranties of merchantability, fitness for a
                    particular purpose, and non-infringement. Milan Laser does not warrant that the Mobile Services will
                    be uninterrupted, timely, secure, virus-free, or error-free.
                </p>
                <p>
                    To the extent permitted by applicable law, you agree that we will not be liable for failed, delayed,
                    or misdirected delivery of any information sent through the Mobile Service, any errors in such
                    information, and/or any action you may or may not take in reliance on the information or Mobile
                    Service.
                </p>
                <p>
                    You are responsible for obtaining and maintaining all devices, software, and services needed to
                    access the Mobile Services. Not all mobile devices or handsets may be supported and our messages may
                    not be deliverable in all areas. Milan Laser, its service providers and the mobile carriers
                    supported by the program are not liable for delayed or undelivered messages.
                </p>
                <p class="p-subhead mb-3 milan-text-primary">
                    <strong>9. Limitation of Liability</strong>
                </p>
                <p>
                    We will not be liable for any damages arising out of or related to your use of the Mobile Service,
                    including, but not limited to, direct, indirect, incidental, consequential, or punitive damages.
                </p>
                <p class="p-subhead mb-3 milan-text-primary">
                    <strong>10. Termination</strong>
                </p>
                <p>
                    We may terminate your access to the Mobile Service at any time, with or without cause. You may also
                    terminate your use of the Mobile Service at any time by opting out as described in Section 3.
                </p>
                <p class="p-subhead mb-3 milan-text-primary">
                    <strong>11. Entire Agreement</strong>
                </p>
                <p>
                    These Terms constitute the entire agreement between you and us with respect to the Mobile Service
                    and supersede all prior or contemporaneous communications and proposals, whether oral or written.
                </p>
                <p class="p-subhead mb-3 milan-text-primary">
                    <strong>12. Amendment</strong>
                </p>
                <p>
                    To the extent permitted by applicable law, Milan Laser may modify or amend these Terms at any time
                    by posting the amended terms on our website or through the Mobile Service. You are responsible for
                    checking these Terms periodically for any updates. Your continued use of the Mobile Service after
                    the posting and/or effective date of any amendments or changes constitutes your acceptance of such
                    amendments or changes.
                </p>
                <p>
                    Milan Laser may suspend, discontinue, or cancel the Mobile Service or any of its features without
                    notice.
                </p>
                <p>
                    Milan Laser shall not be liable for any modification, amendment, suspension, or discontinuance of
                    the Mobile Services.
                </p>
                <p class="p-subhead mb-3 milan-text-primary">
                    <strong>13. Contact Us</strong>
                </p>
                <p>
                    This mobile message program is a service of Milan Laser, located at 17645 Wright Street, Omaha,
                    Nebraska 68130. If you have any questions about these Terms, please contact us at{' '}
                    <a href="tel:18336672967" className="fw-normal">
                        1-833-667-2967
                    </a>{' '}
                    or{' '}
                    <a href="mailto: privacy@milanlaser.com" className="fw-normal">
                        privacy@milanlaser.com
                    </a>
                    .
                </p>
                <p>
                    Milan Laser reserves the right to change any short code or telephone number we use to operate the
                    Mobile Service at any time. You acknowledge that any messages, including any STOP requests, you send
                    to a short code or telephone number we have changed may not be received and we will not be
                    responsible for honoring requests made in such messages.
                </p>

                <p class="p-subhead mb-3 milan-text-primary">
                    <strong>14. Governing Law and Dispute Resolution</strong>
                </p>
                <p>
                    These Terms will be governed by and construed in accordance with the laws of the State of Nebraska
                    without regard to its conflict of law provisions.
                </p>
                <p>Dispute Resolution:</p>
                <ol className="alphabetical">
                    <li>
                        General. In the interest of resolving disputes between you and Milan Laser in the most expedient
                        and cost-effective manner, you and Milan Laser agree that any dispute arising out of or in any
                        way related to these messaging terms and conditions or your receipt of text messages from Milan
                        Laser or its service providers will be resolved by binding arbitration. Arbitration is less
                        formal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or
                        jury, may allow for more limited discovery than in court, and can be subject to very limited
                        review by courts. Arbitrators can award the same damages and relief that a court can award. This
                        agreement to arbitrate disputes includes all claims arising out of or in any way related to
                        these Terms, or your receipt of text messages from Milan Laser or its service providers whether
                        based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and
                        regardless of when a claim arises. YOU UNDERSTAND AND AGREE THAT, BY AGREEING TO THESE TERMS,
                        YOU AND MILAN LASER ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS
                        ACTION AND THAT THESE MESSAGING TERMS SHALL BE SUBJECT TO AND GOVERNED BY THE FEDERAL
                        ARBITRATION ACT.
                    </li>
                    <li>
                        Exceptions. Notwithstanding subsection (a) above, nothing in these Terms will be deemed to
                        waive, preclude, or otherwise limit the right of you or Milan Laser to: (i) bring an individual
                        action in small claims court; (ii) pursue an enforcement action through the applicable federal,
                        state, or local agency if that action is available; (iii) seek injunctive relief in aid of
                        arbitration from a court of competent jurisdiction; or (iv) file suit in a court of law to
                        address an intellectual property infringement claim.
                    </li>
                    <li>
                        Arbitrator. Any arbitration between you and Milan Laser will be governed by the Federal
                        Arbitration Act and the Commercial Dispute Resolution Procedures and Supplementary Procedures
                        for Consumer Related Disputes (collectively, "AAA Rules") of the American Arbitration
                        Association ("AAA"), as modified by these Terms, and will be administered by the AAA. The AAA
                        Rules and filing forms are available online at www.adr.org, by calling the AAA at
                        1-800-778-7879, or by contacting Milan Laser. The arbitrator has exclusive authority to resolve
                        any dispute relating to the interpretation, applicability, or enforceability of this binding
                        arbitration agreement.
                    </li>
                    <li>
                        Notice; Process. If you or Milan Laser intends to seek arbitration, then the party seeking
                        arbitration must first send a written notice of the dispute to the other party by U.S. Mail
                        ("Notice"). Milan Laser’s address for Notice is 17645 Wright Street, Omaha, Nebraska 68130,
                        Attn: Legal Department. The Notice must: (i) describe the nature and basis of the claim or
                        dispute; and (ii) set forth the specific relief sought ("Demand"). You and Milan Laser will make
                        good faith efforts to resolve the claim directly, but if you and Milan Laser do not reach an
                        agreement to do so within thirty (30) days after the Notice is received, you or Milan Laser may
                        commence an arbitration proceeding. During the arbitration, the amount of any settlement offer
                        made by you or Milan Laser must not be disclosed to the arbitrator until after the arbitrator
                        makes a final decision and award, if any. (e) Fees. If you commence arbitration in accordance
                        with these Terms, Milan Laser will reimburse you for your payment of the filing fee, unless your
                        claim is for more than $15,000 or as set forth below, in which case the payment of any fees will
                        be decided by the AAA Rules. If the claim is for $15,000 or less, you may choose whether the
                        arbitration will be conducted: (i) solely on the basis of documents submitted to the arbitrator;
                        (ii) through a non-appearance-based telephone hearing; or (iii) by an in-person hearing as
                        established by the AAA Rules. If the arbitrator finds that either the substance of your claim or
                        the relief sought in the Demand is frivolous or brought for an improper purpose (as measured by
                        the standards set forth in Federal Rule of Civil Procedure 11(b)), then the payment of all fees
                        will be governed by the AAA Rules. In that case, you agree to reimburse Milan Laser for all
                        monies previously disbursed by it that are otherwise your obligation to pay under the AAA Rules.
                        Regardless of the manner in which the arbitration is conducted, the arbitrator must issue a
                        reasoned written decision sufficient to explain the essential findings and conclusions on which
                        the decision and award, if any, are based. You and Milan Laser agree that such written
                        decisions, and information exchanged during arbitration, will be kept confidential except to the
                        extent necessary to enforce or permit limited judicial review of the award. The arbitrator may
                        make rulings and resolve disputes as to the payment and reimbursement of fees or expenses at any
                        time during the proceeding and upon request, from you or Milan Laser made within fourteen (14)
                        days of the arbitrator's ruling on the merits.
                    </li>
                    <li>
                        No Class Actions. YOU AND Milan Laser AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN
                        AN INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
                        REPRESENTATIVE PROCEEDING. Further, unless both you and Milan Laser agree otherwise in a signed
                        writing, the arbitrator may not consolidate more than one person's claims, and may not otherwise
                        preside over any form of a representative or class proceeding.
                    </li>
                    <li>
                        Modifications to this Arbitration Provision. Notwithstanding anything to the contrary in these
                        Terms, if Milan Laser makes any future change to this arbitration provision, other than a change
                        to Milan Laser address for Notice, you may reject the change by sending us written notice within
                        thirty (30) days of the change to Milan Laser address for Notice, in which case this arbitration
                        provision, as in effect immediately prior to the changes you rejected, will continue to govern
                        any disputes between you and Milan Laser.
                    </li>
                    <li>
                        Enforceability. If an arbitrator decides that applicable law precludes enforcement of any of the
                        limitations of subsection (f) above (addressing class, representative, and consolidated
                        proceedings) as to a particular claim for relief, then that claim (and only that claim) must be
                        severed from the arbitration and brought in court. If any other provision of these Messaging
                        Terms is found to be unenforceable, the applicable provision shall be deemed stricken and the
                        remainder of these Terms shall remain in full force and effect.
                    </li>
                </ol>
            </div>
        </div>
    );
}

export default Terms;

<p class="p-subhead mb-3 milan-text-primary">
    <strong>3. </strong>
</p>;
