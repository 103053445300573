// src/components/builder/components/blogs/ConsultationSection.builder.ts
import { Builder } from '@builder.io/react';
import { BUILDER_BLOG_COMPONENTS_NAMES } from '../BuilderComponentsName';
import { ConsultationSection } from './../components/blogs/ConsultationSection.builder';

Builder.registerComponent(ConsultationSection, {
    name: BUILDER_BLOG_COMPONENTS_NAMES.ConsultationSection,
    friendlyName: 'Consultation Section',
    image: 'https://cdn.builder.io/api/v1/image/assets%2F10266dac478d4c768f6b6fbe586ae30e%2Fb23ce742e95e4d3f8e169f199344f663',
    inputs: [
        {
            name: 'headline',
            type: 'string',
            defaultValue: 'Ready To Book Your Free Consultation?',
            required: true,
            helperText: 'Enter headline (max 50 characters)',
            regex: {
                pattern: '^.{1,50}$',
                message: 'Headline must not exceed 50 characters'
            }
        },
        {
            name: 'richTextContent',
            friendlyName: 'Text Content',
            type: 'string',
            required: true,
            defaultValue: `You've read all about the benefits and lasting results of laser hair removal. Now, experience it for yourself. Book <a href="https://milanlaser.com/locations/contact/?gad_source=1&gclid=CjwKCAjwzIK1BhAuEiwAHQmU3tA_ig-TbZdm15qgtv2Em1OAQl5dicRyzhAE4tSl2bqBJPAOOqP15BoCHY8QAvD_BwE#consult" class="text-white font-bold underline">your free consultation</a> with Milan Laser Hair Removal today and start your own hair-free journey. Milan offers payment plans and <a href="https://milanlaser.com/specials/?gad_source=1&gclid=CjwKCAjwzIK1BhAuEiwAHQmU3oyGsiZQOGvj4W0xopzVis2QIsPvRiA8BMdVXu6IbxjCMLYN-A4CFhoCXlsQAvD_BwE" class="text-white font-bold underline">monthly specials</a> that can fit into anyone's budget.`,
            helperText: 'Content will be limited to 500 characters',
            regex: {
                pattern: '^[\\s\\S]{0,500}$',
                message: 'Content exceeds 500 characters. Text will be truncated.'
            },
            onChange: (options) => {
                // Strip HTML tags to count only visible text
                const stripHtml = (html: string): string => html?.replace(/<[^>]*>/g, '') || '';
                const textContent = stripHtml(options.get('richTextContent'));
                
                if (textContent.length > 500) {
                    const truncated = textContent.substring(0, 497) + '...';
                    options.set('richTextContent', truncated);
                }
            }
        },
        {
            name: 'consultationImage',
            friendlyName: 'Consultation Image',
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'webp'],
            required: true,
            defaultValue: 'https://cdn.builder.io/api/v1/image/assets%2F10266dac478d4c768f6b6fbe586ae30e%2Fb23ce742e95e4d3f8e169f199344f663',
        },
        {
            name: 'consultationImageAlt',
            type: 'string',
            friendlyName: 'Image Alt Text',
            defaultValue: 'Milan Laser consultation session',
            required: true,
        },
        {
            name: 'textColor',
            friendlyName: 'Text Color',
            type: 'string',
            enum: ['milan-text-primary', 'milan-text-white', 'milan-text-navy', 'milan-text-black'],
            defaultValue: 'milan-text-white',
            required: true,
            helperText: 'Select the color for the text (primary=blue)',
        },
        {
            name: 'backgroundColor',
            friendlyName: 'Background Color',
            type: 'color',
            defaultValue: 'var(--milan-blue)',
            helperText: 'Select background color for the section',
        }
    ],
});