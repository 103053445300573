import React, { useState } from 'react';
import { Typography } from '../../components/Typography/Typography';
import MilanDownChevron from '../../images/MenuPlusMinus.svg';
import { splitArray } from '../../utils/general/builder';

export type FAQItem = {
    question: string;
    answer: string;
};

export type AccordionItemProps = {
    item: FAQItem;
    index: number;
    isActive: boolean;
    onToggle: (index: number) => void;
};

const AccordionItem: React.FC<AccordionItemProps> = ({ item, index, isActive, onToggle }) => {
    return (
        <div key={index} className="relative">
            <div id="blog-faqs" className="lg:min-h-[128px]">
                {/* Decorative underline */}
                <div className="absolute bottom-0 left-0 right-0 h-px bg-milan-secondary-blue" />
                {/* FAQ Title */}
                <button
                    onClick={() => onToggle(index)}
                    className="w-full text-left border-none bg-transparent"
                    aria-expanded={isActive}
                    aria-controls={`content-${index}`}
                >
                    <div className="p-4 flex justify-between items-center w-full gap-3 lg:gap-5">
                        <Typography variant={'h2SubheadSm'} color={'milan-text-tur'} tag="h2" className="w-full">
                            {item?.question}
                        </Typography>

                        <img
                            src={MilanDownChevron}
                            alt="Down Chevron"
                            className={`${isActive ? 'rotate-180' : ''} w-5`}
                        />
                    </div>
                </button>
                {/* FAQ Content */}
                <div
                    className={`overflow-hidden transition-all duration-300 ease-in-out ${isActive ? 'max-h-auto opacity-100' : 'max-h-0 opacity-0'}`}
                    role="region"
                    aria-labelledby={`heading-${index}`}
                >
                    {/* <p className="px-4 w-full">{item?.answer}</p> */}
                    <p className="px-4 w-full" dangerouslySetInnerHTML={{ __html: item?.answer }} />
                </div>
            </div>
        </div>
    );
};

export function FAQ({ FAQData, heading }: { FAQData: FAQItem[]; heading: string }) {
    const [activeIndices, setActiveIndices] = useState<Record<number, boolean>>({});

    const toggleAccordion = (index: number): void => {
        setActiveIndices((prev) => ({
            // ...prev, // Uncomment this line to allow multiple accordions to be open at the same time
            [index]: !prev[index],
        }));
    };

    const { firstColumnFAQs, secondColumnFAQs, midPoint } = splitArray(FAQData || []);

    return (
        <section className="container mx-auto px-4 py-[50px] md:py-[100px] flex flex-col gap-12">
            <Typography variant={'h2SubheadLg'} color={'milan-text-primary'} tag="h2" id="blog-faqs">
                {heading}
            </Typography>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:px-[10px] lg:gap-5">
                {/* First Column */}
                <div>
                    {firstColumnFAQs.map((faq, index) => (
                        <div key={index}>
                            <div style={{ borderTop: index === 0 ? '1px solid #90D5D5' : 'none' }} />
                            <AccordionItem
                                item={faq}
                                index={index}
                                isActive={activeIndices[index] || false}
                                onToggle={toggleAccordion}
                            />
                        </div>
                    ))}
                </div>
                {/* Second Column */}
                <div>
                    {secondColumnFAQs.map((faq, index) => (
                        <div key={index + midPoint}>
                            {/* Show border on large screen only and Hide the border on the first element in the second column on mobile */}
                            <div
                                style={{ borderTop: index === 0 ? '1px solid #90D5D5' : 'none' }}
                                className="max-lg:hidden"
                            />
                            <AccordionItem
                                item={faq}
                                index={index + midPoint}
                                isActive={activeIndices[index + midPoint] || false}
                                onToggle={toggleAccordion}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
