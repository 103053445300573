import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import GRADUATION_ICON from '../../../images/process/home/Icon-Graduation-Treatment.webp';

export function Graduation() {
    return (
        <section id="graduation" className="background position-relative">
            <div className="container-lg">
                <StaticImage
                    className="d-none d-md-block middle-icon position-absolute top-50 start-50 translate-middle"
                    alt="arrow-left"
                    src="../../../images/process/home/arrow-left.png"
                    imgClassName="middle-icon"
                />
                <div className="row">
                    <div className="col-md-6 border-right">
                        <div className="process-step milan-text-white text-center mx-auto px-sm-4 px-md-5">
                            <h3 className="subpointsm">Your</h3>
                            <h3 className="subpointlg">Graduation</h3>
                            <h3 className="subpointsm">Treatment</h3>
                            <p className="mb-0 pt-4">
                                Do a happy dance because you are up to 95% hair-free! Hooray! You won’t need to come in
                                for regular treatments anymore.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <figure className="text-center h-100 d-flex align-items-center justify-content-center pb-4 pb-md-0">
                            <img
                                alt="Milan Laser Hair Removal Process - Your Graduation Treatment"
                                src={GRADUATION_ICON}
                            />
                        </figure>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Graduation;
