import React from 'react';
import { Link } from 'gatsby';
import chartDesktop from '../../../images/lp/permanence/chart-desktop.webp';
import chartMobile1 from '../../../images/lp/permanence/chart-mobile-1.webp';
import chartMobile2 from '../../../images/lp/permanence/chart-mobile-2.webp';
import baButton from '../../../images/lp/permanence/lp-ba-transparent.png';
import BodyAreasButton from '../../../images/lp/permanence/lp-bodyareas-transparent.png';

export function ResultsVsMethods() {
    return (
        <section className="resultsVsMethods py-5">
            <div className="container">
                <h2 className="subheadlg text-center">Laser Hair Removal Results vs. Other Methods</h2>
                <div className="d-flex justify-content-center align-items-center">
                    <p className="text-center resultMethodP">
                        There are several different ways to remove unwanted facial and body hair. It’s pretty safe to
                        assume that most adults have some experience with shaving, waxing, or tweezing their way to
                        temporarily hair-free skin. However when it comes to hair removal, the results of each method
                        are definitely not equal… especially when it comes to laser hair removal.
                    </p>
                </div>
                <div className="d-none d-sm-block text-center">
                    <img src={chartDesktop} style={{ maxWidth: '70%' }} alt="Comparison Chart Desktop" />
                </div>
                <div className="d-block d-sm-none text-center">
                    <img src={chartMobile1} style={{ maxWidth: '70%' }} alt="Comparison Chart Mobile 1" />
                    <img src={chartMobile2} style={{ maxWidth: '70%' }} alt="Comparison Chart Mobile 2" />
                </div>

                <p className="text-center pt-5">
                    <strong>Laser Hair Removal:</strong> The hair is destroyed at the root so it can’t grow back. EVER.
                    The average person is up to 95% hair-free in 7 to 10 sessions.
                </p>
                <p className="text-center">
                    <strong>Shaving:</strong> It’s a never ending cycle. You may need to drag a razor across your skin
                    weekly or even daily to maintain your results.
                </p>
                <p className="text-center">
                    <strong>Waxing:</strong> Ouch! It’s painful, and to add insult to injury—the hair will just grow
                    back! You’ll need to endure the ripping of waxing strips every month to maintain your results.
                </p>
                <p className="text-center">
                    Learn more about the permanent results that you can get with laser hair removal by scheduling your{' '}
                    <a href="#consult" className="milan-text-primary">
                        free consultation
                    </a>{' '}
                    today!
                </p>

                <div className="row">
                    <div className="d-none d-sm-block col-sm-2">&nbsp;</div>
                    <div className="col-xs-12 col-sm-4">
                        <Link to="/process/beforeafter">
                            <img src={baButton} width="100%" alt="Before & After Button" />
                        </Link>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                        <Link to="/areas">
                            <img src={BodyAreasButton} width="100%" alt="Body Areas Button" />
                        </Link>
                    </div>
                    <div className="d-none d-sm-block col-sm-2">&nbsp;</div>
                </div>
            </div>
        </section>
    );
}

export default ResultsVsMethods;
