import { Builder } from '@builder.io/react';
import { BUILDER_BLOG_AUTHOR_COMPONENTS_NAMES } from '../BuilderComponentsName';
import { AuthorHero } from '../components/blogs/AuthorHero';
// @ts-ignore: This is a JavaScript component without TypeScript definitions
import { builderModels } from '../../../hooks/builderPage';


Builder.registerComponent(AuthorHero, {
    name: BUILDER_BLOG_AUTHOR_COMPONENTS_NAMES.AuthorHero,
    friendlyName: 'Author Hero',
    models: [builderModels.author, builderModels.symbol],
    image: 'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0',
    inputs: [
        {
            name: 'authorName',
            type: 'string',
            defaultValue: 'Dr. Nate Haecker (Dr. H.)',
            helperText: 'Please only enter up to 50 characters',
            required: true,
            hideFromUI: true, //hiding from UI
            friendlyName: 'Author Name',
        },
        {
            name: 'authorAvatar',
            friendlyName: 'Author Avatar Image',
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg', 'webp'],
            required: true,
            hideFromUI: true, //hiding from UI
            defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2F10266dac478d4c768f6b6fbe586ae30e%2F3616924e0b374c41942a1efa41433b41',
        },
        {
            name: 'authorAvatarImageAlt',
            type: 'string',
            friendlyName: 'Author Avatar Image Alt',
            defaultValue: 'Author Avatar Image',
            required: true,
            hideFromUI: true,
        },
        {
            name: 'authorJobTitle',
            type: 'string',
            required: true,
            hideFromUI: true, //hiding from UI
            friendlyName: 'Author Job Title',
            defaultValue: 'Job Title Here',
            helperText: 'Please only enter up to 100 characters',
        },
        {
            name: 'authorIntro',
            type: 'longText',
            required: false,
            hideFromUI: true, //hiding from UI
            friendlyName: 'Author Quick Intro(150 Words Only)',
            helperText: 'Please only enter up to 150 characters',
            defaultValue:
                '',
        },
    ],
});
