import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

export function MedRecUnlimitedPackage() {
    return (
        <section className="milan-py background ocean-bg">
            <div className="container">
                <h2 className="subheadlg milan-text-primary text-lg-center">Benefits of the Unlimited Package</h2>
                <div className="row mt-4">
                    <div className="col-lg-6 text-center">
                        <StaticImage
                            src="../../images/shared/Unlimited-Package.png"
                            alt="The Unlimited Package with Lifetime Guarantee"
                            className="mw-100"
                        />
                    </div>
                    <div className="col-lg-6 mt-4 mt-lg-0">
                        <div className="bg-transparent-5 bg-sm-transparent p-3 p-sm-0 rounded">
                            <p>
                                Body hair plays a major factor in causing a variety of skin issues like folliculitis,
                                hidradenitis suppurativa, and pilonidal cysts. While most people are up to 95% hair-free
                                in 7 to 10 treatments, our exclusive Unlimited Package™ (included, at no additional
                                cost, with every area!) gives you <strong>unlimited treatments for life.</strong>{' '}
                            </p>
                            <p className="mb-0">
                                So, for one affordable price you get all of the sessions you’ll ever need to be hair
                                free. FOR LIFE! That means if a stray or new hair shows up 6 months, or even 10 years
                                down the road… we’ll take care of it permanently for you. No questions asked. No
                                touch-up fees, EVER.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-around align-items-center flex-column flex-md-row mt-3 mt-lg-5">
                    <div>
                        <Link className="milan-btn milan-navy-btn mb-3" to="/specials/">
                            Current Specials
                        </Link>
                    </div>
                    <div>
                        <Link className="milan-btn milan-navy-btn mb-3" to="/process/">
                            Learn How It Works
                        </Link>
                    </div>
                    <div>
                        <Link className="milan-btn milan-navy-btn mb-3" to="/areas/">
                            <span className="d-sm-none d-lg-inline">See Treatable</span> Body Areas
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MedRecUnlimitedPackage;
