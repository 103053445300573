
import { Builder } from '@builder.io/react';
import { BUILDER_BLOG_COMPONENTS_NAMES } from '../BuilderComponentsName';
import { BlogHeroCarousel } from '../components/blogs/BlogHeroCarousel';
// @ts-ignore: This is a JavaScript component without TypeScript definitions
import { builderModels } from '../../../hooks/builderPage';

Builder.registerComponent(BlogHeroCarousel, {
    name: BUILDER_BLOG_COMPONENTS_NAMES.BlogHeroCarousel, 
    friendlyName: 'Blog Hero Carousel',
    models: [
        builderModels.blog,
        builderModels.blogs,
        builderModels.page,
        builderModels.symbol,
        builderModels.blogPostPage,
        builderModels.blogHomePage,
    ] as const,
    image: 'https://cdn.builder.io/api/v1/image/assets%2F10266dac478d4c768f6b6fbe586ae30e%2Fd43dd9277195480dad336e9099bf6ef5',
});