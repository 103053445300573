import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Typography } from '../../../../Typography/Typography';
import { useBuilderDataModelsContent } from '../../../../../hooks/builder/useBuilderDataModelsContent';
import { useUrlParams } from '../../../../../hooks/useUrlParams';
import SortSelector from './SortSelector';
import { CategoryFilter } from './CategoryFilter';
import { CardList } from './Carlist';
import { navigate } from 'gatsby';
import { getAuthorUrl, getItemsPerPageByBreakpoint, getTotalItems } from '../../../../../components/builder/utils/blog';
import {
    addAllCategoriesToBlogs,
    capitalizeFirstLetter,
    compareUrls,
    filterByCategories,
    sortBlogs,
    uniqueCategories,
} from '../../../../../utils/general/builder';
import { BREAKPOINTS } from '../../../../../utils/constants';
import { useBreakpoint } from 'use-breakpoint';
import { usePagination } from '../../../../../hooks/builder/usePagination';
import { Pagination } from './Pagination';
import { useScrollToAnchor } from '../../../../../hooks/builder/useScrollToAnchor';

// Types
import type { BuilderComponentState } from '@builder.io/react/dist/types/src/components/builder-component.component';
import type { AuthorModelDataType, BlogPageData } from 'components/builder/types';
import type { BlogDataModel, BlogDataType } from 'components/builder/types/blogs/blogHero';

const COMPONENT_ID = 'our-latest-blogs';

// Constants
const DEFAULT_SORT = 'newest';
const DEFAULT_FILTER = 'All Categories';

/**
 * Props for the OurLatestBlogs component
 */
interface LatestBlogsProps {
    model?: string;
    initialSort?: 'newest' | 'oldest';
    initialFilter?: string;
    headline: string;
    builderState?: BuilderComponentState;
}

/**
 * Displays a filterable, sortable list of blog posts with pagination
 */
export function OurLatestBlogs({
    initialSort = DEFAULT_SORT,
    initialFilter = DEFAULT_FILTER,
    headline = 'Our Latest Blogs',
}: LatestBlogsProps) {
    // State
    const [selectedSort, setSelectedSort] = useState(initialSort);
    const [activeFilter, setActiveFilter] = useState(initialFilter);
    const [blogsData, setBlogsData] = useState<{
        blogs: BlogDataModel[];
        blogPages: BlogPageData[];
        isLoading: boolean;
    }>({
        blogs: [],
        blogPages: [],
        isLoading: true,
    });

    // Hooks
    const { data: authors = [] } = useBuilderDataModelsContent<AuthorModelDataType[]>('authors');
    const { getUrlParam } = useUrlParams();
    const { scrollToAnchor } = useScrollToAnchor();

    // Responsive design setup
    const { minWidth } = useBreakpoint(BREAKPOINTS, 'xxs');
    const itemsPerPage = getItemsPerPageByBreakpoint(minWidth);

    // Process blogs - extract from raw data
    const processedBlogs = useMemo(() => {
        if (blogsData.isLoading) return { publishedBlogs: [], categories: [] };

        // Extract blog data
        const blogObjects = blogsData.blogs.map((result) => ({ data: result.data })) || [];

        // Add all categories and filter by published pages
        const blogsWithCategories = addAllCategoriesToBlogs(blogObjects);
        const { matchingBlogData: publishedBlogs = [] } = compareUrls(blogsData.blogPages, blogsWithCategories);

        // Extract unique categories
        const categoriesArray = publishedBlogs.map((blog) => blog?.data?.categories).flat();
        const categories = categoriesArray ? uniqueCategories(categoriesArray) : [];

        return { publishedBlogs, categories };
    }, [blogsData]);

    // Apply current filters and sorting
    const filteredAndSortedBlogs = useMemo(() => {
        const { publishedBlogs } = processedBlogs;
        if (!publishedBlogs || publishedBlogs.length === 0) return [];

        const filtered = filterByCategories(publishedBlogs, [activeFilter]);
        return sortBlogs(filtered, selectedSort);
    }, [processedBlogs, activeFilter, selectedSort]);

    // Pagination
    const pagination = usePagination<BlogDataType>({
        itemsPerPage,
        results: filteredAndSortedBlogs,
        anchor: COMPONENT_ID,
    });

    // Load data on mount
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [blogs, blogPages] = await Promise.all([
                    getTotalItems<BlogDataModel>('blogs'),
                    getTotalItems<BlogPageData>('blog-page'),
                ]);

                setBlogsData({
                    blogs,
                    blogPages,
                    isLoading: false,
                });
            } catch (error) {
                console.error('Failed to fetch blog data:', error);
                setBlogsData((prev) => ({ ...prev, isLoading: false }));
            }
        };

        fetchData();
    }, []);

    // Sync filter with URL parameter
    useEffect(() => {
        const currentCategory = capitalizeFirstLetter(getUrlParam('category') || '');
        if (currentCategory) {
            setActiveFilter(currentCategory);
        }
    }, [getUrlParam]);

    // Event handlers
    const handleFilterChange = useCallback(
        (filter: string) => {
            if (filter === activeFilter) return;
            setActiveFilter(filter);
            pagination.handlePageChange(1); // Reset to first page when filter changes
            scrollToAnchor(COMPONENT_ID);
        },
        [activeFilter, pagination.handlePageChange, scrollToAnchor],
    );

    const handleSortChange = useCallback((sort: 'newest' | 'oldest') => {
        setSelectedSort(sort);
    }, []);

    const handleAuthorClick = useCallback((authorName: string) => {
        const slug = getAuthorUrl(authorName);
        navigate(slug);
    }, []);

    // Empty state
    if (blogsData.isLoading) {
        return <div className="container mx-auto py-12 text-center">Loading blogs...</div>;
    }

    if (!filteredAndSortedBlogs || filteredAndSortedBlogs.length === 0) {
        return null;
    }

    return (
        <div className="container mx-auto py-[50px] px-4 flex flex-col gap-[10px] w-full" id={COMPONENT_ID}>
            {/* Header section */}
            <div className="flex justify-between mb-6">
                <Typography variant={'h2SubheadLg'} color={'text-milan-blue'} tag="h2">
                    {headline}
                </Typography>
                <SortSelector selectedSort={selectedSort} onSortChange={handleSortChange} />
            </div>

            {/* Categories section */}
            <div className="flex justify-between items-center overflow-x-auto">
                <CategoryFilter
                    categories={processedBlogs.categories}
                    activeFilter={activeFilter}
                    onFilterChange={handleFilterChange}
                />
            </div>

            {/* Blog list */}
            <CardList
                activeFilter={activeFilter}
                authors={authors!}
                blogs={pagination.currentResults || []}
                handleAuthorClick={handleAuthorClick}
                handleFilterChange={handleFilterChange}
            />

            {/* Pagination controls */}
            <div className="py-10 self-center">
                <Pagination
                    currentPage={pagination.currentPage}
                    totalPages={pagination.totalPages}
                    totalItems={pagination.totalItems}
                    itemsPerPage={itemsPerPage}
                    onPageChange={pagination.handlePageChange}
                    getPageNumbers={pagination.getPageNumbers}
                    getPageNumbersForMobile={pagination.getPageNumbersForMobile}
                    minWidth={minWidth}
                    showingText="Showing"
                />
            </div>
        </div>
    );
}
