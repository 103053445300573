import { BlogPageData, BuilderPageContent, SymbolContent } from "components/builder/types";
import { BlogDataType } from "components/builder/types/blogs/blogHero";

// Define a type for the object with the 'categories' array inside 'data', Add more properties as needed
interface DataObjectWithCategories {
    data: {
      categories: string[];
      publishedDate: string;
    };
  }


  type SortOption = 'newest' | 'oldest';
  


//Write a function to Calculate the midpoint for splitting the array
export function splitArray<T>(arr: T[]) {
    const midPoint = Math.ceil(arr.length / 2);
    const firstColumnFAQs = arr?.slice(0, midPoint);
    const secondColumnFAQs = arr?.slice(midPoint);
    return { firstColumnFAQs, secondColumnFAQs, midPoint };
}

// Function to create a unique array of categories
export const uniqueCategories = (categories: string[]) => {
    return Array.from(new Set(categories));
};


  // Define the function to filter based on categories
  export function filterByCategories<T extends DataObjectWithCategories>(
    objects: T[], 
    categories: string[]
  ): T[] {
    return objects.filter(obj => 
      obj.data && obj.data.categories.some(category => categories.includes(category))
    );
  }

  export function sortBlogs<T extends DataObjectWithCategories>(data: T[], sortOption: SortOption): T[] {
    if(!data) return [];
    return data.sort((a, b) => {
      const aDate = new Date(a.data.publishedDate).getTime();
      const bDate = new Date(b.data.publishedDate).getTime();
        if (sortOption === 'newest') {
            return bDate - aDate; // Newest first (larger timestamp comes first)
        } else {
            return aDate - bDate; // Oldest first (smaller timestamp comes first)
        }
    });
}


export const capitalizeFirstLetter = (str: string): string => {
    return str
        .split(' ') // Split the string by spaces into an array of words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter and lowercase the rest
        .join(' '); // Join the words back together into a string
};

/**
 * Adds 'All Categories' to the categories array if it doesn't exist
 * @param blogs - Array of blog data objects
 * @returns Updated array of blog data with 'All Categories' added to categories
 */
export const addAllCategoriesToBlogs = (blogs: BlogDataType[]): BlogDataType[] => {
    return blogs.map((blog) => {
        const currentCategories = blog.data.categories || [];

        // Only add 'All Categories' if it's not already present
        if (!currentCategories.includes('All Categories')) {
            return {
                ...blog,
                data: {
                    ...blog.data,
                    categories: ['All Categories', ...currentCategories],
                },
            };
        }

        return blog;
    });
};


/**
 * Removes 'All Categories' from the categories array if it exists
 * @param blogs - Array of blog data objects
 * @returns Updated array of blog data with 'All Categories' removed from categories
 */
export const removeAllCategoriesFromBlogs = (blogs: BlogDataType[]): BlogDataType[] => {
    return blogs.map((blog) => {
        const currentCategories = blog.data.categories || [];

        // Remove 'All Categories' if it exists
        const filteredCategories = currentCategories.filter((category) => category !== 'All Categories');

        // Only create a new object if we actually removed 'All Categories'
        if (filteredCategories.length !== currentCategories.length) {
            return {
                ...blog,
                data: {
                    ...blog.data,
                    categories: filteredCategories,
                },
            };
        }

        return blog;
    });
};

/**
 * Removes 'All Categories' from a string array if it exists
 * @param categories - Array of category strings
 * @returns New array with 'All Categories' removed if it existed
 */
export const removeAllCategoriesFromStringArr = (categories: string[]): string[] => {
    return categories.filter(category => category !== 'All Categories');
  };
  

//   /**
//    * Gets the blogs array with results from BuilderComponentState
//    * @param builderState - The BuilderComponentState object
//    * @returns The blogs array with results, or undefined if not found
//    */
//     export  const getBlogsWithResults = (builderState: BuilderComponentState): BlogDataType[] | undefined => {
//       if (!builderState?.state) return undefined;
  
//       // Find the first key that starts with 'blogs' and has a results array
//       const blogsKey = Object.keys(builderState.state).find(
//           (key) => key.startsWith('blogs') && Array.isArray(builderState.state[key]?.results),
//       );
  
//       return blogsKey ? builderState.state[blogsKey].results : undefined;
//   };



  /**
   * Compares URLs between BlogPageData and BlogDataType arrays
   * @param blogPages Array of BlogPageData objects
   * @param blogData Array of BlogDataType objects
   * @returns Object containing matching and non-matching URLs
   */
  export const compareUrls = (
      blogPages: BlogPageData[],
      blogData: BlogDataType[],
  ): {
      matchingUrls: string[];
      nonMatchingBlogPageUrls: string[];
      matchingBlogData: BlogDataType[];
  } => {
      // Extract URLs from blogPages
      const blogPageUrls = blogPages?.map((page) => page?.data?.url);
  
      // Initialize result object
      const result = {
          matchingUrls: [] as string[],
          nonMatchingBlogPageUrls: [] as string[],
          matchingBlogData: [] as BlogDataType[],
      };
  
      // Check each URL from blogPages
      blogPageUrls?.forEach((url) => {
          // Find matching blog data
          const matchingBlog = blogData?.find((blog) => {
              const derivedUrl = blog?.data?.pageUrl || generateUrlFromHeadline(blog?.data?.headline || '');
              return url === derivedUrl;
          });
  
          if (matchingBlog) {
              result.matchingUrls.push(url);
              result.matchingBlogData.push(matchingBlog);
          } else {
              result.nonMatchingBlogPageUrls.push(url);
          }
      });
  
      return result;
  };
  
  /**
   * Helper function to generate a URL from a headline
   */
  const generateUrlFromHeadline = (headline: string): string => {
      const headlineUrl = headline
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, '-')
          .replace(/^-|-$/g, '');
      return '/' + headlineUrl; // adding '/' to the beginning of the URL before returning
  };

  export const getSymbolsBlockContentFromBuilderData = (data: BuilderPageContent, symbol: SymbolContent[]) => {
    const resolvedBlocks = data?.blocks?.map((block) => {
        // Check if the block is a Symbol component and has an entry ID
        if (block?.component?.name === 'Symbol' && block?.component?.options?.symbol?.entry) {
            const symbolId = block.component.options.symbol.entry;
            const symbolData = symbol.find((symbol) => symbol.id === symbolId); // Find the symbol by its ID
            return symbolData?.content?.data?.blocks?.[0] || block; // Return the first block of the symbol, or the original block if not found
        }
        return block;
    });
    return resolvedBlocks;
};