import { Builder } from '@builder.io/react';
import { BUILDER_BLOG_COMPONENTS_NAMES } from '../BuilderComponentsName';
import { BlogLayout } from '../components/blogs/BlogLayout';
import '../components/blogs/BlogsSectionStyles.css';

Builder.registerComponent(BlogLayout, {
    name: BUILDER_BLOG_COMPONENTS_NAMES.BlogLayout,
    friendlyName: 'Blog Layout',
    inputs: [
        {
            name: 'headline',
            type: 'string',
            required: true,
            defaultValue: 'Is Laser Hair Removal Permanent?',
            helperText: 'Enter headline (max 50 characters)',
            regex: {
                pattern: '^.{1,50}$',
                message: 'Headline must not exceed 50 characters',
            },
        },
        {
            name: 'introduction',
            type: 'longText',
            required: true,

            defaultValue:
                'Many hair-removal options are available to consumers, and they all want the most bang for their buck to go with lasting results. Laser hair removal is a popular method, but is it permanent? Read on to find out.',
            helperText: 'Introduction content (max 500 characters)',
            onChange: (options) => {
                const introduction = options.get('introduction');
                if (introduction.length > 500) {
                    options.set('introduction', introduction.slice(0, 500));
                    alert('Introduction must not exceed 500 characters.');
                }
            },
        },
        {
            name: 'content',
            type: 'richText',
            required: true,
            defaultValue: `<p>No hair removal method is fully permanent, but laser hair removal offers the most significant and long-lasting reduction in hair growth available. Since it significantly delays hair-growth frequency, you'll rarely ever need to rely on any other hair-removal method.</p>
        
        <h3 id="targeting-the-hair-follicle" class='p-subhead-tw'>Targeting the Hair Follicle</h3>
        
        <p>Unlike temporary methods that focus on removing hair at the surface, laser hair removal targets the hair follicle (the tiny pocket within the skin that nourishes and houses the hair) itself. By focusing on the follicle, laser hair removal disrupts a crucial process: hair growth.</p>

        <p>Lasers emit a concentrated beam of light specifically absorbed by melanin, a pigment found in hair. This absorption converts light energy into heat, which damages the follicle's growth center, significantly weakening the follicle's ability to produce new hair growth.</p>
        
        <h3 id="multiple-sessions" class='p-subhead-tw'>Multiple Sessions Needed</h3>
        
        <p>You won't be completely hair-free after your first session, but you'll start seeing results. Sessions are required roughly every six weeks. The hair will be in different growth phases throughout the hair-free journey, and different laser settings will be required for optimal results to leave you with smooth, hair-free skin.</p>
        
        <h3 id="significant" class='p-subhead-tw'>Significant Reduction, Not Elimination</h3>
        
        <p>Multiple sessions, typically spaced every six weeks, are necessary to target hair in various growth phases. However, with each treatment, you'll experience progressively smoother skin. Following your initial sessions, maintenance touch-ups are typically less frequent, allowing you to enjoy long-lasting results.</p>
        
        <h3 id="maintenance" class='p-subhead-tw'>Maintenance Sessions Possible</h3>
        
        <p>Even when your sessions conclude, you may see some regrowth over time. In order to maintain ideal results, touch-up sessions may be necessary. The frequency varies from person to person, but is typically less frequent than regular shaving or waxing, saving both time and money in the long run. Milan Laser clients usually see hair reduction by 95% and are able to continue seeing permanent results with our Unlimited Package™. You pay one price for a body area and are hair-free for life. That means even months or years down the road if you need a touch-up or additional treatment, you won't pay another dime for that body area.</p>`,
            helperText: 'Main content - use h2 tags for section headings and p tags for paragraphs',
        },
        {
            name: 'tableOfContents',
            type: 'list',
            subFields: [
                {
                    name: 'title',
                    type: 'string',
                    required: true,
                    helperText: 'Section title as it appears in table of contents',
                },
                {
                    name: 'anchor',
                    type: 'url',
                    required: true,
                    helperText: 'URL anchor for this section (e.g., faqs)',
                },
            ],
            defaultValue: [
                {
                    title: 'Targeting the Hair Follicle',
                    anchor: '#targeting-the-hair-follicle',
                },
                {
                    title: 'Multiple Sessions Needed',
                    anchor: '#multiple-sessions',
                },
                {
                    title: 'Significant Reduction, Not Elimination',
                    anchor: '#significant',
                },
                {
                    title: 'Maintenance Sessions Possible',
                    anchor: '#maintenance',
                },
                {
                    title: 'Maximizing Results and Longevity',
                    anchor: '#maximizing',
                },
                {
                    title: 'Completing the Recommended Treatment Plan',
                    anchor: '#completing',
                },
                {
                    title: 'Maintaining a Healthy Lifestyle',
                    anchor: '#maintaining',
                },
                {
                    title: 'Following Aftercare Instructions',
                    anchor: '#section-with-image',
                },
                {
                    title: 'FAQs',
                    anchor: '#blog-faqs',
                },
            ],
            helperText: 'Add entries to appear in the table of contents.',
        },
    ],
});
