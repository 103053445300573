interface UseScrollToAnchorOptions {
  offset?: number;
  behavior?: ScrollBehavior;
  delay?: number;
}

/**
 * Custom hook to handle scrolling to anchor elements
 * 
 * @param options - Configuration options for scrolling
 * @returns An object with scroll functions
 */
export const useScrollToAnchor = (
  options: UseScrollToAnchorOptions = {}
) => {
  const {
    offset = 100,
    behavior = 'smooth',
  } = options;

  /**
   * Scrolls to the specified anchor element
   */
  const scrollToAnchor = (targetAnchorId: string | null | undefined) => {
        if (!targetAnchorId) return;
        
        const anchorElement = document.getElementById(targetAnchorId);
        if (!anchorElement) return;
        
        // Get the element's position
        const elementPosition = anchorElement.getBoundingClientRect().top;
        // Calculate position with offset
        const offsetPosition = elementPosition + window.scrollY - offset;
        
        // Scroll to the element
        window.scrollTo({
        top: offsetPosition,
        behavior
        });
    }


  return {
    scrollToAnchor,
  };
};