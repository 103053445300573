import { useCallback, useMemo, useState } from 'react';
import { useScrollToAnchor } from './useScrollToAnchor';


interface UsePaginationProps<T> {
    itemsPerPage: number;
    results?: T[];
    anchor?: string;
 }


export const usePagination = <T>({ 
    itemsPerPage = 10, 
    results, 
}: UsePaginationProps<T>) => {
    
    const [currentPage, setCurrentPage] = useState(1);
    
    // Memoize total calculations to prevent unnecessary recalculations
    const totalItems = useMemo(() => results?.length || 0, [results]);
    const totalPages = useMemo(() => 
        totalItems && Math.ceil(totalItems / itemsPerPage) || 0, 
        [totalItems, itemsPerPage]
    );

    // Use our custom scrolling hook
    const { scrollToAnchor } = useScrollToAnchor();

    // Get current page items with memoization
    const getCurrentItems = useCallback(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return results?.slice(startIndex, endIndex);
    }, [currentPage, itemsPerPage, results]);

    const currentResults = useMemo(() => getCurrentItems(), [getCurrentItems]);

    // Handle page change
    const handlePageChange = useCallback((pageNumber: number) => {
        setCurrentPage(pageNumber);
        scrollToAnchor('our-latest-blogs');
    }, [setCurrentPage]);

    // Calculate the range of visible page numbers
    const getPageNumbers = useCallback(() => {
        const visiblePages = [];

        // Add pages 1 to 4 if we're at the start
        if (currentPage <= 3) {
            for (let i = 1; i <= Math.min(4, totalPages); i++) {
                visiblePages.push(i);
            }
        } else {
            // Otherwise show 1, ..., and then pages around current
            visiblePages.push(1);

            // Add current page and surrounding pages
            for (let i = currentPage - 1; i <= Math.min(currentPage + 1, totalPages); i++) {
                if (i > 1) visiblePages.push(i);
            }
        }

        // Add last page if not already included
        if (totalPages > 4 && !visiblePages.includes(totalPages)) {
            visiblePages.push(totalPages);
        }

        return visiblePages;
    }, [currentPage, totalPages]);

    // Calculate the range of visible page numbers for mobile devices
    const getPageNumbersForMobile = useCallback(() => {
        const visiblePages: number[] = [];

        // Always show the first page when on the last page, and there are more than one page
        if (currentPage === totalPages && !visiblePages.includes(1) && totalPages > 1) {
            visiblePages.push(1);
        }

        // Always show the current page
        visiblePages.push(currentPage);

        // Always show the last page
        if (totalPages > 1 && !visiblePages.includes(totalPages)) {
            visiblePages.push(totalPages);
        }

        return visiblePages;
    }, [currentPage, totalPages]);


    return {
        currentPage,
        totalPages,
        totalItems,
        handlePageChange,
        getPageNumbers,
        getPageNumbersForMobile,
        currentResults,
    };
};