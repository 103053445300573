import React from 'react';
import { LHRBenefits } from '../../../lp/LHRBenefits';

export default function LHRBenefitsBuilder() {
    return (
        <div id="social-page">
            <LHRBenefits />
        </div>
    );
}
