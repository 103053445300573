import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import { MilanTextColor, TypographyProps, TypographyVariant } from '../builder/types';

// Define typography variants with their corresponding Tailwind classes
const typographyVariants: Record<TypographyVariant, string> = {
    // Hero Headers (H1)
    // Mobile: 50px size / 50px line height
    // Desktop: 54px size / 54px line height
    // Uses Brandon Grotesque Light with uppercase text
    // letterSpacing: -2px;
    h1Hero: `
        font-brandon font-light uppercase tracking-tight
        text-h1-hero-mobile leading-h1-hero-mobile
        md:text-h1-hero-desktop md:leading-h1-hero-desktop
    `,
    // Small Hero Headers (H1)
    // Mobile: 32px size / 32px line height
    // Desktop: 40px size / 40px line height
    // Uses Brandon Grotesque Light with uppercase text
    // letterSpacing: -2px;

    h1SmallTitle: `font-brandon md:text-h1-small-title-desktop text-h1-small-title-mobile tracking-tight md:leading-h1-small-title-desktop leading-h1-small-title-mobile font-light`,
    // Large Subheaders (H2)
    // Mobile: 38px size / 44px line height
    // Desktop: 54px size / 60px line height
    // Uses Brandon Grotesque Light with capitalized text
    h2SubheadLg: `
        font-brandon font-light capitalize tracking-tight
        text-h2-subheadlg-mobile leading-h2-subheadlg-mobile
        md:text-h2-subheadlg-desktop md:leading-h2-subheadlg-desktop
    `,

    // Small Subheaders (H2)
    // Mobile: 28px size / 32px line height
    // Desktop: 32px size / 36px line height
    // Uses Brandon Grotesque Regular with capitalized text
    h2SubheadSm: `
        font-brandon font-regular capitalize tracking-slight-tight
        text-h2-subheadsm-mobile leading-h2-subheadsm-mobile
        md:text-h2-subheadsm-desktop md:leading-h2-subheadsm-desktop
    `,

    // Large Subpoints (H3)
    // Mobile: 28px size / 32px line height / -1px letter spacing
    // Desktop: 48px size / 48px line height / -2px letter spacing
    // Uses Brandon Grotesque Medium with uppercase text
    h3SubpointLg: `
    font-brandon font-medium uppercase tracking-slight-tight md:tracking-tight
    text-h3-subheadlg-mobile leading-h3-subpointlg-mobile
    md:text-h3-subheadlg-desktop md:leading-h3-subpointlg-desktop
    `,

    // Small Subpoints (H3)
    // Mobile: 20px size / 24px line height
    // Desktop: 32px size / 36px line height
    // Uses Brandon Grotesque Medium with uppercase text
    h3SubpointSm: `
        font-brandon font-medium uppercase tracking-slight-tight
        text-h3-subpointsm-mobile leading-h3-subpointsm-mobile
        md:text-h3-subpointsm-desktop md:leading-h3-subpointsm-desktop
    `,

    // Footer Headers (H4)
    // Mobile: 16px size / 18px line height
    // Desktop: 14.4px size / 16px line height
    // Uses Brandon Grotesque Bold with uppercase text
    h4Footer: `
        font-brandon font-bold uppercase tracking-normal
        text-h4-footer-mobile leading-h4-footer-mobile
        md:text-h4-footer-desktop md:leading-h4-footer-desktop
    `,

    // Subheaders (H5)
    // Mobile & Desktop: 24px size / 28px line height
    // Uses Brandon Grotesque Regular
    h5Subheader: `
    font-brandon font-regular tracking-normal
    text-h5-mobile leading-h5-subheader
    md:text-h5-desktop md:leading-h5-subheader
    `,

    // Form Subheaders (H6)
    // Mobile: 20px size / 26px line height
    // Desktop: 18px size / 24px line height
    // Uses Brandon Grotesque Medium with capitalized text
    h6FormSubheader: `
    font-open-sans font-semibold capitalize tracking-normal
    text-h6-form-subheader-mobile leading-h6-form-subheader-mobile
    md:text-h6-form-subheader-desktop md:leading-h6-form-subheader-desktop
    `,
    // Default Paragraph Text
    // Mobile: 18px size / 30px line height
    // Desktop: 16px size / 28px line height
    // Uses Open Sans Regular with paragraph tracking
    pDefault: `
     font-open-sans font-regular tracking-paragraph
    text-paragraph-mobile leading-paragraph-mobile
    md:text-paragraph-desktop md:leading-paragraph-desktop
    [&_a]:text-milan-blue [&_a]:font-bold [&_strong]:text-milan-blue [&_strong]:font-bold
    [&_ul]:list-disc !important [&_ul]:pl-1 [&_ol]:list-decimal !important [&_ol]:pl-6 
    [&_li]:mb-2 [&_li]:ml-6 [&_li]:list-item !important
`,

    // Paragraph Subheaders
    // Mobile: 18px size / 33.6px line height
    // Desktop: 16px size
    // Uses Open Sans Regular with paragraph tracking
    pSubhead: `
        font-open-sans font-regular capitalize tracking-paragraph
        text-paragraph-subhead leading-subheader-paragraph
        [&_strong]:font-bold
    `,
    // Paragraph Subheaders
    // Mobile: 24px size / 33.6px line height
    // Desktop: 24px size
    // Uses Open Sans Regular with paragraph tracking
    pSubheadLg: `
        font-open-sans font-bold tracking-paragraph
        text-paragraph-subhead leading-subheader-paragraph
        capitalize
    `,

    // Oversized Paragraphs
    // Mobile: 48px size / 48px line height
    // Desktop: 64px size / 64px line height
    // Uses Brandon Grotesque Light
    pOversized: `
        font-brandon font-light tracking-tight
        text-oversized-paragraph-mobile leading-oversized-paragraph-mobile
        md:text-oversized-paragraph-desktop md:leading-oversized-paragraph-desktop
    `,

    // Pull Quotes
    // Mobile: 24px size / 33.6px line height
    // Desktop: 48px size / 48px line height
    // Uses Brandon Grotesque Light
    'p-PullQuote': `
    font-brandon font-light tracking-normal md:tracking-tight
    text-pull-quote-mobile leading-pull-quote-mobile
    md:text-pull-quote-desktop md:leading-pull-quote-desktop
    `,

    // Fine Print Text (milan-fp)
    // 12px size / 18px line height
    // Uses Open Sans Regular with paragraph tracking
    milanFp: `
        font-open-sans font-regular tracking-paragraph
        text-milan-fp leading-milan-fp
    `,

    // Strong Text
    // Inherits size from parent
    // Uses Open Sans Bold (700) with paragraph tracking
    strong: `
        font-open-sans font-strong tracking-paragraph
        inherit
    `,

    // Emphasized Text
    // Inherits size from parent
    // Uses Open Sans SemiBold (600) with italic style and paragraph tracking
    em: `
        font-open-sans font-em tracking-paragraph
        italic
        inherit
    `,
};

export function Typography({
    variant,
    children,
    className = 'm-0',
    color = MilanTextColor.Navy,
    tag = 'span',
    ...rest
}: TypographyProps & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    const variantClasses = typographyVariants[variant] || '';
    return React.createElement(
        tag,
        { className: `typography-${variant} ${variantClasses} ${color} ${className}`.trim(), ...rest },
        children,
    );
}
