import { Builder } from '@builder.io/react';
import { BUILDER_DIVIDER_NAMES } from '../BuilderComponentsName';
import { AboutMilanSectionBuilder } from '../components/blogs/AboutMilanSection.builder';

Builder.registerComponent(AboutMilanSectionBuilder, {
    name: BUILDER_DIVIDER_NAMES.AboutMilanSection,
    friendlyName: 'About Milan Section',
    inputs: [
        {
            name: 'heading',
            type: 'text',
            defaultValue: 'About Milan',
            required: true,
            helperText: 'Enter heading (max 50 characters)',
            regex: {
                pattern: '^.{1,50}$',
                message: 'Heading must not exceed 50 characters'
            }
        },
        {
            name: 'headingColor',
            friendlyName: 'Heading Color',
            type: 'string',
            enum: ['milan-text-primary', 'milan-text-white', 'milan-text-navy', 'milan-text-black'],
            defaultValue: 'milan-text-primary',
            required: true,
            helperText: 'Select the color for the heading text (primary=blue)',
        },
        {
            name: 'richTextContent',
            friendlyName: 'Text Content',
            type: 'richText',
            required: true,
            defaultValue: `<p class="milan-text-navy">Milan Laser was founded in 2012 by two Board-Certified medical doctors. Our goal is to provide state of the art laser treatments in a safe, comfortable environment in every one of our <a href="https://milanlaser.com/locations/" class="milan-text-primary">{{locationCount}}+ locations</a>.</p><p class="milan-text-navy">Our treatments are safe, and all of our procedures are performed by highly-trained medical professionals. Our lasers are FDA cleared and are tailored to your specific skin type & hair color.</p><p class="milan-text-navy"> To discover more about the amazing benefits of laser hair removal, call us directly at <span class="text-milan-blue font-bold" style="white-space: nowrap;">1-833-NO-RAZOR</span> or visit us at a convenient location near you and set up a free consultation today!</p>`,
        },
        {
            name: 'sectionImage',
            friendlyName: 'Section Image',
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'webp'],
            required: true,
            defaultValue: 'https://cdn.builder.io/api/v1/image/assets%2F10266dac478d4c768f6b6fbe586ae30e%2Fb23ce742e95e4d3f8e169f199344f663',
        },
        {
            name: 'sectionImageAlt',
            type: 'string',
            friendlyName: 'Section Image Alt Text',
            defaultValue: 'Milan Laser Office Interior',
            required: true,
        },
        {
            name: 'isImageOnRight',
            friendlyName: 'Is Image on Right?',
            type: 'boolean',
            defaultValue: true,
        }
    ],
});