import { useEffect, useMemo, useState } from 'react';
import { builder } from '@builder.io/sdk';
import builderConfig from '../../builder-config';
import { getBuilderData } from '../api/builderContent';
import { builderModels } from './builderPage';

const apiKey = builderConfig?.builderAPIKey;

builder.init(apiKey);

export function useBuilderPromotionsBannerData() {
    const [content, setContent] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const result = await getBuilderData(builderModels.promotionsBanners, {
                    sort: {
                        createdDate: -1, // Sort the content by created date in descending order
                    },
                    options: {
                        published: 'published',
                    },
                });
                setContent(result);
            } catch (error) {
                console.log('unable to fetch builder data', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const promotionalBannersTextData = {
        grandSpecialsBanner: content?.data?.grandSpecialsBanner,
        specialsBanner: content?.data?.specialsBanner,
    };

    return useMemo(() => {
        return {
            promotionalBannersTextData,
            builderContentData: content,
            isLoading: loading,
        };
    }, [content, loading, promotionalBannersTextData]);
}
