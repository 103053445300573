import { Builder } from '@builder.io/react';
import { PullQuoteBuilder } from '../components/blogs/PullQuote.builder';
import { BUILDER_DIVIDER_NAMES } from '../BuilderComponentsName';

Builder.registerComponent(PullQuoteBuilder, {
    name: BUILDER_DIVIDER_NAMES.PullQuote,
    friendlyName: 'Pull Quote',
    image: 'https://cdn.builder.io/api/v1/image/assets%2F10266dac478d4c768f6b6fbe586ae30e%2Fa9500308536c401288922d0a57a824e0',
    inputs: [
        {
            name: 'quoteText',
            type: 'longText',
            defaultValue: '...Dr. Haecker has helped Milan Laser become the nation’s largest provider of laser hair removal with more than 380 clinics nationwide."',
            required: true,
            helperText: 'Enter the quote text (max 250 characters)',
            regex: {
                pattern: '^.{0,250}$',
                message: 'Quote text must not exceed 250 characters'
            }
        },
        {
            name: 'textColor',
            friendlyName: 'Text Color',
            type: 'string',
            enum: ['milan-text-primary', 'milan-text-white', 'milan-text-navy', 'milan-text-tur', 'milan-text-black'],
            defaultValue: 'milan-text-tur',
            required: true,
            helperText: 'Select the color for the quote text'
        },
        {
            name: 'backgroundColor',
            type: 'color',
            defaultValue: 'rgba(194, 194, 194, 0.10)',
            helperText: 'Select background color for the quote section'
        }
    ]
});
 