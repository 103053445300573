import React, { useContext } from 'react';
import { GlobalSiteDataContext } from '../../../../context';
import { AboutMilanSectionProps } from '../../types';
import { Typography } from '../../../Typography/Typography';

export function AboutMilanContent({
    heading,
    richTextContent,
    headingColor,
    sectionImage,
    sectionImageAlt,
    isImageOnRight,
}: AboutMilanSectionProps) {
    const siteData = useContext(GlobalSiteDataContext);
    const locationCount = siteData?.milanOpenStores?.countToDisplay;

    const processedContent = richTextContent?.replace('{{locationCount}}', locationCount || '370');

    return (
        <div className="flex flex-col lg:flex-row gap-y-6 lg:gap-x-6">
            <div className={`w-full  ${!isImageOnRight ? 'lg:order-2' : ''}`}>
                <Typography variant="h2SubheadLg" color={headingColor} className="mb-3 break-words capitalize" tag="h2">
                    {heading}
                </Typography>
                <Typography variant="pDefault" className="break-words whitespace-normal" tag="p">
                    <div dangerouslySetInnerHTML={{ __html: processedContent }} />
                </Typography>
            </div>
            <div className={`w-full  ${!isImageOnRight ? 'lg:order-1' : ''}`}>
                <div className="min-h-[181px]  sm:min-w-full sm:h-auto md:w-[720px] md:h-auto lg:w-full lg:h-auto 
                  xl:w-[636px]">
                    <img
                        src={sectionImage}
                        alt={sectionImageAlt}
                        className="w-full h-full rounded-[10px] object-cover"
                    />
                </div>
            </div>
        </div>
    );
}
