import React from 'react';
import { Typography } from '../../../Typography/Typography';
import { TableOfContentsProps } from '../../types/tableOfContents';

export function TableOfContents({ entries }: TableOfContentsProps) {
 
    const handleClick = (anchor: string) => (e: React.MouseEvent) => {
        e.preventDefault();
        
        const cleanAnchor = anchor.replace('#', '');
        const element = document.getElementById(cleanAnchor);
        
        if (element) {
            const offset = 200;  
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - offset;
    
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }
    };
    return (
        // Hide on mobile, show on desktop
        <div className="hidden lg:block w-[258px]">
            <div className="flex flex-col gap-4">
                {entries.map((entry, index) => (
                    <div key={entry.anchor} className="flex flex-col gap-2">
                          <a 
                          href={entry.anchor}
                            onClick={handleClick(entry.anchor)}
                            className="no-underline hover:opacity-80"
                        >
                            <Typography 
                                variant="pDefault"
                                color="milan-text-primary"
                                tag="p"
                            >
                                <strong>{entry.title}</strong>
                            </Typography>
                        </a>
                        {/* Don't show divider after last item */}
                        {index < entries.length - 1 && (
                            <div className="w-full h-[1px] bg-milan-secondary-blue" />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}