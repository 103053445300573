export const milanDesignTokens = {
    designTokensOptional: true,
    designTokens: {
        colors: [
            { name: 'Milan Navy', value: 'var(--milan-navy, #003542)' },
            { name: 'Milan Blue', value: 'var(--milan-blue, #0088AB)' },
            { name: 'Milan Secondary Blue', value: 'var(--milan-secondary-blue, #90d5d5)' },
            { name: 'Milan Turquoise', value: 'var(--milan-turquoise, #00A5B4)' },
            { name: 'Milan Red', value: 'var(--milan-red, #F13A1A)' },
            { name: 'Milan White', value: 'var(--milan-white, #f9fbfb)' },
            { name: 'Milan Off White', value: 'var(--milan-off-white, #EFF7F7)' },
            { name: 'Milan Grey', value: 'var(--milan-grey, #C2C2C2)' },
            { name: 'Milan Light Grey', value: 'var(--milan-lt-grey, #efefef)' },
            { name: 'Text Primary', value: 'var(--milan-blue, #0088AB)' },
            { name: 'Text Secondary', value: 'var(--milan-secondary-blue, #90d5d5)' },
            { name: 'Text Navy', value: 'var(--milan-navy, #003542)' },
            { name: 'Text Turquoise', value: 'var(--milan-turquoise, #00A5B4)' },
            { name: 'Text Red', value: 'var(--milan-red, #F13A1A)' },
            { name: 'Text White', value: 'var(--milan-white, #f9fbfb)' },
            { name: 'Text Black', value: 'var(--bs-black, #000000)' },
        ],
        spacing: [
            // All around padding
            { name: 'Padding Y 50', value: '3.125rem 0' },
            { name: 'Padding Y 100', value: '6.25rem 0' },
            { name: 'Padding Y 150', value: '9.375rem 0' },

            // Top padding
            { name: 'Padding Top 50', value: '3.125rem 0 0 0' },
            { name: 'Padding Top 100', value: '6.25rem 0 0 0' },
            { name: 'Padding Top 150', value: '9.375rem 0 0 0' },

            // Bottom padding
            { name: 'Padding Bottom 50', value: '0 0 3.125rem 0' },
            { name: 'Padding Bottom 100', value: '0 0 6.25rem 0' },
            { name: 'Padding Bottom 150', value: '0 0 9.375rem 0' },
        ],

        fontFamily: [
            { name: 'Brandon Grotesque', value: "'BrandonGrotesque', sans-serif" },
            { name: 'Open Sans', value: "'Open Sans', sans-serif" },
        ],

        fontStyle: [{ name: 'Emphasized Text', value: 'italic' }],

        fontSize: [
            // Headers
            { name: 'H1 Hero', value: '3.125rem' },
            { name: 'H1 Small', value: '2rem' },
            { name: 'H2 Large', value: '2rem' },
            { name: 'H2 Small', value: '1.75rem' },
            { name: 'H3 Large', value: '1.5rem' },
            { name: 'H3 Small', value: '1.25rem' },
            { name: 'H4', value: '1rem' },
            { name: 'H5', value: '1.5rem' },
            { name: 'H6', value: '1.25rem' },
            { name: 'Oversized', value: '4rem' },
            { name: 'H1.Smalltitle - Mobile', value: '2rem' },
            { name: 'H1.Smalltitle - Desktop', value: '2.5rem' },
            { name: 'H2.Subheadlg - Mobile', value: '2.375rem' },
            { name: 'H2.Subheadlg - Desktop', value: '3.375rem' },
            { name: 'H2.Subheadsm - Mobile', value: '1.75rem' },
            { name: 'H2.Subheadsm - Desktop', value: '2rem' },
            { name: 'H3.Subpointsm - Mobile', value: '1.25rem' },
            { name: 'H3.Subpointsm - Desktop', value: '2rem' },
            { name: 'H4 Footer - Mobile', value: '1rem' },
            { name: 'H4 Footer - Desktop', value: '0.9rem' },
            { name: 'H5 Subheader', value: '1.5rem' },
            { name: 'H6 Form Subheader - Mobile', value: '1.25rem' },
            { name: 'H6 Form Subheader - Desktop', value: '1.125rem' },
            { name: 'Paragraph - Mobile', value: '1.125rem' },
            { name: 'Paragraph - Desktop', value: '1rem' },
            { name: 'Subheader Paragraph', value: '1.5rem' },
            { name: 'Oversized Paragraph - Mobile', value: '3rem' },
            { name: 'Oversized Paragraph - Desktop', value: '4rem' },
        ],

        fontWeight: [
            { name: 'Light', value: '300' },
            { name: 'Regular', value: '400' },
            { name: 'Medium', value: '500' },
            { name: 'Bold', value: '700' },
            { name: 'Black', value: '900' },
            { name: 'H2.Subheadsm Regular', value: '400' },
            { name: 'H3.Subpointsm Medium', value: '500' },
            { name: 'H4 Footer Bold', value: '700' },
            { name: 'Emphasized Text', value: '600' },
            { name: 'Subheader Strong', value: '700' },
            { name: 'Oversized Light', value: '300' },
        ],

        lineHeight: [
            { name: 'Hero H1', value: '3.125rem' },
            { name: 'H1 Small', value: '2rem' },
            { name: 'H2 Large', value: '2.375rem' },
            { name: 'H2 Small', value: '2rem' },
            { name: 'H3', value: '1.5rem' },
            { name: 'H4', value: '1.125rem' },
            { name: 'H5', value: '1.75rem' },
            { name: 'H6', value: '1.625rem' },
            { name: 'H2.Subheadsm - Mobile', value: '2rem' },
            { name: 'H2.Subheadsm - Desktop', value: '2.25rem' },
            { name: 'H3.Subpointsm - Mobile', value: '1.5rem' },
            { name: 'H3.Subpointsm - Desktop', value: '2.25rem' },
            { name: 'H4 Footer - Mobile', value: '1.125rem' },
            { name: 'H4 Footer - Desktop', value: '1rem' },
            { name: 'H5 Subheader', value: '1.75rem' },
            { name: 'H6 Form Subheader - Mobile', value: '1.625rem' },
            { name: 'H6 Form Subheader - Desktop', value: '1.5rem' },
            { name: 'Paragraph - Mobile', value: '1.875rem' },
            { name: 'Paragraph - Desktop', value: '1.75rem' },
            { name: 'Subheader Paragraph', value: '2.1rem' },
            { name: 'Oversized Paragraph - Mobile', value: '3rem' },
            { name: 'Oversized Paragraph - Desktop', value: '4rem' },
        ],

        letterSpacing: [
            { name: 'Tight', value: '-2px' },
            { name: 'Slight Tight', value: '-1px' },
            { name: 'Normal', value: '0' },
            { name: 'Paragraph', value: '0.1px' },
        ],
        textTransform: [
            { name: 'H2.Subheadsm', value: 'capitalize' },
            { name: 'H3.Subpointsm', value: 'uppercase' },
            { name: 'H4 Footer', value: 'uppercase' },
            { name: 'H6 Form Subheader', value: 'capitalize' },
        ],
    },
};
