export const BUILDER_DIVIDER_NAMES = {
    WaxingCostPerYear: 'WaxingCostPerYear',
    UnlimitedPackage: 'UnlimitedPackage',
    UnlimitedPackage2: 'UnlimitedPackage2',
    ReasonsLHRVimeo: 'Reasons LHR Vimeo',
    AffordablePlansDivider: 'ResultsDivider',
    MilanCost: 'MilanCost',
    RatingsDivider: 'RatingsDivider',
    LipsOrUnderArmsDivider: 'LipsOrUnderArmsDivider',
    RatingsAndLipsOrUnderarmDivider: 'RatingsAndLipsOrUnderarmDivider',
    HeroWithImageAndLinkOnly: 'HeroWithImageAndLinkOnly',
    DynamicHero: 'DynamicHero',
    DynamicHeroV2: 'DynamicHeroV2',
    WhyMilanLHRIsIdeal: 'Why Milan LHR is Ideal',
    SectionDivider: 'SectionDivider',
    ShavingAndWaxingCalc: 'ShavingAndWaxingCalc',
    LHRBenefits: 'LHR Benefits',
    LocationsBanner: 'LocationsBanner',
    WhatCustomersSay: 'WhatCustomersSay',
    CreditApproval: 'CreditApproval',
    HowLHRWorks: 'How LHR Works',
    RichTextContent: 'RichTextContent',
    PopularBlogs: 'PopularBlogs',
    AuthorHero: 'Author Hero',
    AboutMilanSection: 'AboutMilanSection',
    AboutAuthor: 'AboutAuthor',
    PullQuote: 'PullQuote',
    BlogPostHero: 'BlogPostHero',
    ConsultationSection: 'ConsultationSection',
    SectionWithImage: 'SectionWithImage',
    BlogSection: 'BlogSection',
    BlogLayout: 'BlogLayout',
    BlogTextSection: 'BlogTextSection',
    BlogHeroCarousel: 'BlogHeroCarousel',
};

export const BUILDER_BLOG_AUTHOR_COMPONENTS_NAMES = {
    AuthorHero: 'Author Hero',
    AboutMilanSection: 'AboutMilanSection',
    AboutAuthor: 'AboutAuthor',
    PullQuote: 'PullQuote',
};
export const BUILDER_BLOG_COMPONENTS_NAMES = {
    AuthorHero: 'Author Hero',
    AboutMilanSection: 'AboutMilanSection',
    AboutAuthor: 'AboutAuthor',
    PullQuote: 'PullQuote',
    BlogPostHero: 'BlogPostHero',
    ConsultationSection: 'ConsultationSection',
    SectionWithImage: 'SectionWithImage',
    BlogSection: 'BlogSection',
    BlogLayout: 'BlogLayout',
    BlogTextSection: 'BlogTextSection',
    BlogFAQs: 'BlogFAQs',
    BlogHeroCarousel: 'BlogHeroCarousel',
    OurLatestBlogs: 'OurLatestBlogs',
};
