import { useEffect, useMemo, useState } from 'react';
import { getAllBuilderData } from '../../api/builderContent';

export function useBuilderDataModelsContent<T>(model: string) {
    const [data, setPageData] = useState<{ result: T | null, isDataFetching: boolean}>({
        result: null,
        isDataFetching: false,
    });

    useEffect(() => {
        setPageData((prevContent) => ({
            ...prevContent,
            isDataFetching: true,
        }));
        const getData = async () => {
            try {
                const data:T = await getAllBuilderData(model);
                setPageData((prevContent) => ({
                    ...prevContent,
                    result: data,
                    isDataFetching: false,
                }));
            } catch (error) {
                console.error('Error fetching builder page content:', error);
                setPageData((prevContent) => ({
                    ...prevContent,
                    isDataFetching: false,
                }));
            }
        };
        getData();
    }, [model]);

    return useMemo(() => {
        return {
            model: model,
            data: data?.result,
            isDataFetching: data?.isDataFetching,
        };
    }, [data, model]);
}
