import { navigate } from 'gatsby';
import { useCallback, useEffect, useState } from 'react';

type WindowLocation = {
    search: string;
} | null;

interface UrlParamsHook {
    getUrlParam: (param: string) => string | null;
    addUrlParam: (param: string, value: string) => void;
    removeUrlParam: (param: string) => void;
    location: WindowLocation;
}

export const convertSpacesToHyphens = (value: string): string => value.replace(/\s+/g, '-').toLowerCase();

export const replaceHyphensWithSpace = (value: string): string => value.replace(/-/g, ' ');

export const useUrlParams = (): UrlParamsHook => {
    const [location, setLocation] = useState<WindowLocation>(typeof window !== 'undefined' ? window.location : null);

    // Update location when it changes
    useEffect(() => {
        if (typeof window !== 'undefined') {
            setLocation(window.location);
        }
    }, []);

    // Get the current search params
    const getSearchParams = useCallback((): URLSearchParams => {
        if (typeof window === 'undefined') return new URLSearchParams();
        return new URLSearchParams(window.location.search);
    }, []);

    // Get the value of a specific URL parameter
    const getUrlParam = useCallback(
        (param: string): string | null => {
            const searchParams = getSearchParams();
            const value = searchParams.get(param);

            // decodeURIComponent will replace %20 with spaces, so we replace them with hyphens
            return value ? decodeURIComponent(replaceHyphensWithSpace(value)) : null;
        },
        [getSearchParams],
    );

    // Add or update a URL parameter
    const addUrlParam = useCallback(
        (param: string, value: string): void => {
            const searchParams = getSearchParams();
            // encodeURIComponent will replace spaces with %20, so we replace them with hyphens
            const formattedValue = encodeURIComponent(convertSpacesToHyphens(value));
            searchParams.set(param, formattedValue);

            // Update URL without adding to history stack
            navigate(`?${searchParams.toString()}`, {
                replace: true,
            });
        },
        [getSearchParams],
    );

    // Remove a specific URL parameter
    const removeUrlParam = useCallback(
        (param: string): void => {
            const searchParams = getSearchParams();
            searchParams.delete(param);

            const newSearch = searchParams.toString();
            navigate(newSearch ? `?${newSearch}` : '', {
                replace: true,
            });
        },
        [getSearchParams],
    );

    return {
        getUrlParam,
        addUrlParam,
        removeUrlParam,
        location,
    };
};
